import {
  all,
  takeLeading,
  call,
  put,
  select,
  race,
  take,
  fork,
  takeEvery,
} from 'redux-saga/effects';
import {
  NEXT_USER_START,
  DISCOVER_START,
  DISCOVER_SUCCESS,
  DISCOVER_FAILURE,
  LIKE_START,
  GET_FRIENDS_SUCCESS,
  GET_FRIENDS_FAILURE,
} from '../actionTypes';
import rsf, { db } from 'redux/rsf';
import {
  discoverSuccess,
  discoverFailure,
  discover as discoverAction,
  nextUserFailure,
  nextUserSuccess,
  likeSuccess,
  likeFailure,
} from 'redux/actions/discover.action';

const genders = {
  Man: 'Woman',
  Woman: 'Man',
};

function* discover() {
  try {
    const loadingFriends = yield select(({ Chat }) => Chat.loading)
    if (loadingFriends) {
      yield race({ success: take(GET_FRIENDS_SUCCESS), failure: take(GET_FRIENDS_FAILURE) });
    }

    const userID = yield select(({ Auth }) => Auth.user.id);
    const currentDiscoverID = yield select(({ Auth }) => Auth.user.currentDiscoverID);
    const gender = genders[yield select(({ Auth }) => Auth.user.gender)] || genders.Man;
    const friends = yield select(({ Chat }) => Chat.friends);
    const friendIDs = {}
    friends.forEach(friend => {
      friendIDs[friend.id] = true;
    });

    let search = db.collection("users")
      .where('gender', '==', gender)
      .where('signupStep', '==', 'done')
      .orderBy('id')
      .limit(3)
    if (currentDiscoverID) {
      search = search.startAfter(currentDiscoverID)
    }
    let result = yield call(rsf.firestore.getCollection, search);

    if (result.empty) {
      yield call(rsf.firestore.updateDocument, `users/${userID}`, { currentDiscoverID: null });
      return yield call(discover);
    }

    const users = result.docs.filter(doc => !friendIDs[doc.id]).map(doc => doc.data());

    if (users.length === 0) {
      const currentDiscoverID = result.docs[result.docs.length - 1].id;
      yield call(rsf.firestore.updateDocument, `users/${userID}`, { currentDiscoverID });
      return yield call(discover);
    }

    yield put(discoverSuccess({ users }));
  } catch (err) {
    yield put(discoverFailure(err));
  }
}

function* nextUser() {
  try {
    const userID = yield select(({ Auth }) => Auth.user.id);
    const currentUser = yield select(({ Discover }) => Discover.currentUser);
    let users = yield select(({ Discover }) => Discover.users);

    if (currentUser && currentUser.id !== users[users.length - 1].id) {
      const currentIndex = users.findIndex(user => user.id === currentUser.id);
      const next = users[currentIndex + 1];
      yield fork(rsf.firestore.updateDocument, `users/${userID}`, { currentDiscoverID: next.id });
      yield put(nextUserSuccess(next));
    } else {
      yield put(discoverAction());
      const result = yield race({ success: take(DISCOVER_SUCCESS), failure: take(DISCOVER_FAILURE) });
      if (result.failure) {
        return yield put(nextUserFailure(result.failure.payload));
      }

      users = yield select(({ Discover }) => Discover.users);
      const currentDiscoverID = users[0].id
      yield fork(rsf.firestore.updateDocument, `users/${userID}`, { currentDiscoverID })
      yield put(nextUserSuccess(users[0]));
    }
  } catch (err) {
    yield put(nextUserFailure(err));
  }
}

function* like({ payload = {} }) {
  try {
    const userTarget = yield select(({ Discover }) => Discover.currentUser);
    const user = yield select(({ Auth }) => Auth.user);
    const { targetType = 'Profile' } = payload;
    yield call(
      rsf.firestore.addDocument,
      'likes',
      {
        target: db.doc(`users/${userTarget.id}`),
        user: db.doc(`users/${user.id}`),
        targetUser: `${userTarget.firstName} ${userTarget.lastName}`,
        targetType,
      }
    );
    // TODO: channel creation should be done in a cloud function
    yield call(
      rsf.firestore.addDocument,
      'channels',
      {
        creator_id: user.id,
        lastMessage: null,
        lastMessageDate: null,
        leader: false,
        users: [user.id, userTarget.id],
      }
    )
    yield put(likeSuccess());
    yield fork(nextUser);
  } catch (err) {
    yield put(likeFailure(err));
  }
}

export default function* authSaga() {
  yield all([
    takeLeading(NEXT_USER_START, nextUser),
    takeLeading(DISCOVER_START, discover),
    takeEvery(LIKE_START, like),
  ]);
}
