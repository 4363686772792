import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';

import logo from 'assets/images/logo/icon.svg';
// import app, { db } from 'redux/rsf';
import Loader from 'components/common/loader';
import { signup } from 'redux/actions/auth.action';

const Signup = () => {
  const dispatch = useDispatch();
  const logged = useSelector(({ Auth }) => Auth.logged);
  const signingUp = useSelector(({ Auth }) => Auth.signingUp);

  const register = payload => dispatch(signup(payload))

  if (logged) return <Redirect to={process.env.PUBLIC_URL} />;

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* <!-- sign up page start--> */}
          <div className="authentication-main py-0">
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center"><img src={logo} className="w-25" alt="" /></div>
                    <div className="card mt-4 p-4">
                      <h4 className="text-center">NEW USER</h4>
                      <h6 className="text-center">Enter your Email and Password For Signup</h6>
                      <Formik
                        initialValues={{
                          email: '',
                          password: '',
                          firstName: '',
                          lastName: '',
                          leader: false,
                        }}
                        onSubmit={register}
                      >
                        {({ isSubmitting }) => (
                          <Form className="theme-form">
                            {signingUp && <Loader />}
                            <div className="form-row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="col-form-label">First Name</label>
                                  <Field className="form-control" required name='firstName' type="text" placeholder="John" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="col-form-label">Last Name</label>
                                  <Field className="form-control" required name='lastName' type="text" placeholder="Deo" />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">Email</label>
                              <Field className="form-control" required name='email' type="email" placeholder="example@dths.com" />
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">Password</label>
                              <Field className="form-control" required name='password' type="password" placeholder="**********" />
                            </div>
                            <div className="form-group">
                              <div className="checkbox p-0 text-right">
                                <Field className="" id="checkbox1" name='leader' type="checkbox" />
                                <label htmlFor="checkbox1">I'm a church leader</label>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col-12">
                                <button disabled={isSubmitting} className="btn btn-primary btn-block" type="submit">Sign Up</button>
                              </div>
                              <div className="col">
                                <div className="text-center mt-3 m-l-20">Are you already user?  <Link className="btn-link text-capitalize" to="/login">Login</Link></div>
                              </div>
                            </div>
                            {/* <div className="form-divider"></div> */}
                            {/* <div className="social mt-3">
                          <div className="form-group btn-showcase d-flex">
                            <button className="btn social-btn btn-fb d-inline-block"> <i className="fa fa-facebook"></i></button>
                            <button className="btn social-btn btn-twitter d-inline-block"><i className="fa fa-google"></i></button>
                            <button className="btn social-btn btn-google d-inline-block"><i className="fa fa-twitter"></i></button>
                            <button className="btn social-btn btn-github d-inline-block"><i className="fa fa-github"></i></button>
                          </div>
                        </div> */}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Signup;