import {
  // Home,
  // Heart,
  // MessageCircle,
  // User,
  Users,
  // Search
} from 'react-feather';

export const MENU_ITEMS = [
  {
    // title: 'Profile', icon: User, type: 'link', path: '/users/userEdit', badgeType: 'primary', active: false,
    // children: [
    //     { path: '/dashboard/default', title: 'Default', type: 'link' },
    //     { path: '/dashboard/ecommerce', title: 'E-Commerce', type: 'link' },
    //     { path: '/dashboard/university', title: 'University', type: 'link' },
    //     { path: '/dashboard/crypto', title: 'Crypto', type: 'link' },
    //     { path: '/dashboard/project', title: 'Project', type: 'link' }
    // ]
  },
  // {
  //   path: '/discover', title: 'Discover', icon: Search, type: 'link', active: false
  // },
  // {
  //   title: 'Connect', icon: MessageCircle, type: 'link', path: '/connect', active: false, leader: true
  // },
  // {
  //   path: '/sample/samplepage', title: 'Likes', icon: Heart, type: 'link', active: false, leader: true
  // }
  // ,
  // {
  //   path: '/leaders', title: 'Leaders', icon: Users, type: 'link', active: false, leader: true
  // }
  // ,
  {
    path: '/user-requests', title: 'Requests by Users to Add Leader', icon: Users, type: 'link', active: false, admin: true
  }
  ,
  {
    path: '/leader-requests', title: 'Requests by Leaders to be Added', icon: Users, type: 'link', active: false, admin: true
  },
  {
    path: '/leaders/new', title: 'New Leader', icon: Users, type: 'link', active: false, admin: true
  }
];
