import firebase from 'firebase/app'
import { all, put, takeEvery, call, take, select, fork } from 'redux-saga/effects'
import rsf, { messaging } from 'redux/rsf';
import { showToast } from 'redux/actions/toast.action';
import {
  setMessagingToken,
  setMessagingTokenSuccess,
  setMessagingTokenFailure,
} from '../actions/messaging.action'
import {
  SET_MESSAGING_TOKEN_START,
  SYNC_USER_SUCCESS,
} from '../actionTypes'

function* requestPermission() {
  if (!messaging) return;
  try {
    yield take(SYNC_USER_SUCCESS);
    yield messaging.requestPermission();
    const token = yield messaging.getToken();
    yield put(setMessagingToken(token))
  } catch (error) {
    console.warn('Notifications blocked')
  }
}

function* messageHandler() {
  const messageChannel = yield rsf.messaging.channel();

  yield takeEvery(messageChannel, function* (message) {
    console.log(message)
    if (message.notification) {
      if (message.notification.body) {
        yield put(showToast({ type: 'info', message: message.notification.body }));
      } else if (message.notification.title) {
        yield put(showToast({ type: 'info', message: message.notification.title }));
      }
    }
    // handle data notification if needed here
  });
}

function* setToken({ payload: token }) {
  try {
    const userID = yield select(({ Auth }) => Auth.user.id)
    yield call(
      rsf.firestore.updateDocument,
      `users/${userID}`,
      {
        pushToken: firebase.firestore.FieldValue.arrayUnion(token)
      }
    )
    yield put(setMessagingTokenSuccess());
  } catch (error) {
    yield put(setMessagingTokenFailure());
  }
}

export default function* () {
  if (messaging) {
    yield takeEvery(SET_MESSAGING_TOKEN_START, setToken);
    yield requestPermission();
    yield all([
      fork(messageHandler),
      rsf.messaging.syncToken({
        successActionCreator: setMessagingToken,
      }),
    ])
  }
}
