import { combineReducers } from 'redux';
import Customizer from './customizer.reducer';
import Chat from './chat.reducer';
import Auth from './auth.reducer';
import Question from './question.reducer';
import Country from './country.reducer';
import Church from './church.reducer';
import Leader from './leader.reducer';
import Discover from './discover.reducer';
import User from './user.reducer';
import Request from './request.reducer';

const reducers = combineReducers({
  Customizer,
  Question,
  Chat,
  Auth,
  Country,
  Church,
  Leader,
  Discover,
  User,
  Request,
});

export default reducers;
