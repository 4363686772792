import React from 'react'
export const data =[
    {
        email:"test@test.com",
        name: "Leader Test",
        status: <i className="fa fa-circle font-success f-12" />,
        church:"church1",
        phone:"123456778"
    },
    {
        email:"test@test.com",
        name: "Leader Testu",
        status: <i className="fa fa-circle font-danger f-12" />,
        church:"church2",
        phone:"123456778"
    },
    {
        email:"test@test.com",
        name: "Leader Testnu",
        status: <i className="fa fa-circle font-warning f-12" />,
        church:"church3",
        phone:"123456778"
    },
    {
        email:"test@test.com",
        name: "Leader TestMenu",
        status: <i className="fa fa-circle font-danger f-12" />,
        church:"church4",
        phone:"123456778"
    },
    {
        email:"test@test.com",
        name: "Leader Testvu",
        status: <i className="fa fa-circle font-success f-12" />,
        church:"church5",
        phone:"123456778"
    },
    {
        email:"test@test.com",
        name: "Leader Testvvu",
        status: <i className="fa fa-circle font-danger f-12" />,
        church:"church6",
        phone:"123456778"
    }
]

export default data