import { all, takeLeading, call, put } from 'redux-saga/effects';
import {
  GET_QUESTIONS_START,
} from '../actionTypes';
import rsf from 'redux/rsf';
import { getQuestionsSuccess, getQuestionsFailure } from 'redux/actions/question.action';

function* getQuestions() {
  try {
    const result = yield call(rsf.firestore.getCollection, 'questions');
    const questions = result.docs.map(doc => doc.data().question);
    yield put(getQuestionsSuccess(questions));
  } catch (err) {
    yield put(getQuestionsFailure(err));
  }
}

export default function* authSaga() {
  yield all([
    takeLeading(GET_QUESTIONS_START, getQuestions),
  ]);
}
