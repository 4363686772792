import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux**
import { Provider, useSelector } from 'react-redux';
import store from './redux/store/index';
import App from "./components/app";

// auth
import SignIn from './components/auth/signIn';
import SignUp from './components/auth/signUp';

// Import custom Components 
import Default from './components/dashboard/defaultCompo/default';
import Chat from './components/applications/chat-app/chat';
import UserEdit from './components/users/userEdit';
import Loader from 'components/common/loader';
import SignUpSteps from 'components/signUpSteps/signUpSteps';
import Discover from 'components/discover/discover';

import Leader from './components/tables/dataTableComponent'
import RequestShow from 'components/requests/requestShow';
import LeaderRequests from 'components/leaderRequests/leaderRequests';
import LeaderRequestShow from 'components/leaderRequests/leaderRequestShow';
import { db } from 'redux/rsf';
import UserRequests from 'components/userRequests/userRequests';
import UserRequestShow from 'components/userRequests/userRequestShow';
import ApprovedLeaderShow from 'components/leaderRequests/approvedLeaderShow';
import CreateLeader from 'components/leaderRequests/createLeader';

function Root() {
  const abortController = new AbortController();
  const loadingAuth = useSelector(({ Auth }) => Auth.loadingAuth);
  const signingUp = useSelector(({ Auth }) => Auth.signingUp);

  useEffect(() => {
    const layout = localStorage.getItem('layout_version')
    document.body.classList.add(layout);
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;

    return function cleanup() {
      abortController.abort();
    }

  }, [abortController]);

  if (loadingAuth) {
    return <Loader />;
  }

  window.a = db

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter basename={'/'}>
        <ScrollContext>
          <Switch>
            <Route path={`${process.env.PUBLIC_URL}/login`} component={SignIn} />
            {/* <Route path={`${process.env.PUBLIC_URL}/signup`} component={SignUp} /> */}
            <Route path={`${process.env.PUBLIC_URL}/signup-steps`} component={SignUpSteps} />
            <Route path={`${process.env.PUBLIC_URL}/requests/:requestId`} component={RequestShow} />
            {
              !signingUp ?
                <App>
                  <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={UserRequests} />
                    <Route exact path={`${process.env.PUBLIC_URL}/dashboard/default`} component={Default} />
                    <Route path={`${process.env.PUBLIC_URL}/users/userEdit`} component={UserEdit} />
                    <Route path={`${process.env.PUBLIC_URL}/discover`} component={Discover} />
                    <Route path={`${process.env.PUBLIC_URL}/connect/:channelId?`} component={Chat} />
                    <Route exact path={`${process.env.PUBLIC_URL}/leaders`} component={Leader} />
                    <Route exact path={`${process.env.PUBLIC_URL}/leaders/new`} component={CreateLeader} />
                    <Route exact path={`${process.env.PUBLIC_URL}/leaders/:leaderId`} component={ApprovedLeaderShow} />
                    <Route exact path={`${process.env.PUBLIC_URL}/user-requests`} component={UserRequests} />
                    <Route exact path={`${process.env.PUBLIC_URL}/user-requests/:requestId`} component={UserRequestShow} />
                    <Route exact path={`${process.env.PUBLIC_URL}/leader-requests`} component={LeaderRequests} />
                    <Route exact path={`${process.env.PUBLIC_URL}/leader-requests/:requestId`} component={LeaderRequestShow} />
                  </Switch>
                </App>
                : <Loader />
            }
          </Switch>
        </ScrollContext>
      </BrowserRouter>
    </div>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(function (registration) {
      // console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}