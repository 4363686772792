import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/common/loader';
import UserShow from 'components/users/userShow';
import Breadcrumb from '../common/breadcrumb';
import { getLeaderRequest, replyLeaderRequest, setLeaderRequest } from 'redux/actions/request.action';
import { Redirect } from 'react-router';

const LeaderRequestShow = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const loading = useSelector(({ Request }) => Request.loading);
  const request = useSelector(({ Request }) => Request.current);
  const [replied, setReplied] = useState(false)

  const requestId = params.requestId;

  const handleApprove = useCallback(() => {
    const payload = {
      id: requestId,
      status: 'active',
    }
    dispatch(replyLeaderRequest(payload));
  }, [dispatch, requestId]);

  const handleDecline = useCallback(() => {
    const payload = {
      id: requestId,
      status: 'denied',
    }
    dispatch(replyLeaderRequest(payload));
  }, [dispatch, requestId]);

  useEffect(() => {
    if (!request) {
      dispatch(getLeaderRequest({ id: requestId }))
    }
    return () => dispatch(setLeaderRequest(null));
    // eslint-disable-next-line
  }, [requestId, dispatch])

  if (loading) return <Loader />

  if (replied) {
    return <Redirect to="/leader-requests" />
  }

  const photos = [request.leader.profilePicture, ...(request.leader?.photos || [])].filter(photo => photo?.fullURL);

  const actions = (
    <div className="mt-auto align-self-end">
      {
        request.status === 'pending' ?
          <>
            <button
              className="btn btn-success m-r-10"
              type="button"
              data-original-title="btn btn-info-gradien"
              title=""
              onClick={() => {
                handleApprove()
                setReplied(true)
              }}
            >
              Verify
            </button>
            <button
              className="btn btn-danger m-r-10"
              type="button"
              data-original-title="btn btn-info-gradien"
              title=""
              onClick={() => {
                setReplied(true)
                handleDecline()
              }}
            >
              Deny
            </button>
          </> : null
      }
    </div>
  )

  return (
    <>
      <Breadcrumb title={`${request.leader.firstName} ${request.leader.lastName}`} parent="Leader requests" />
      {request.status !== 'pending' && (
        <h5 className="text-center mt-0" style={{ verticalAlign: 'middle' }}>
          <br />
            This request has already been answered
        </h5>
      )}
      <UserShow
        actions={actions}
        photos={photos}
        user={{ ...request.leader, ...request }}
        leader={true}
        request={request}
      />
    </>
  );
}

export default LeaderRequestShow
