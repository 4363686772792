import { put, call, takeLatest, all, fork, take, cancel, select } from 'redux-saga/effects';
import rsf from 'redux/rsf';
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  syncUserSuccess,
  signupSuccess,
  signupFailure,
  requestEmailVerificationSuccess,
  requestEmailVerificationFailure,
} from "../actions/auth.action";
import {
  LOGOUT_START,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SIGNUP_START,
  REQUEST_EMAIL_VERIFICATION_START,
} from '../actionTypes';
import { showToast } from 'redux/actions/toast.action';

function* login({ payload }) {
  const { email, password } = payload;
  try {
    yield call(rsf.auth.signInWithEmailAndPassword, email, password);

  }
  catch (error) {
    yield put(loginFailure(error));
    yield put(showToast({ type: 'error', message: error.message }))
  }
}

function* signup({ payload }) {
  try {
    let { email, password, firstName, lastName, leader } = payload;
    email = email.toLowerCase();
    const role = leader ? 'leader' : 'user';
    const { user } = yield call(rsf.auth.createUserWithEmailAndPassword, email, password);
    yield call(
      rsf.firestore.setDocument,
      `users/${user.uid}`,
      {
        id: user.uid,
        active: false,
        verified: false,
        subscribed: false,
        signupStep: 'step 1',
        firstName,
        lastName,
        role,
        email,
      }
    )
    yield put(signupSuccess());
  }
  catch (error) {
    yield put(signupFailure(error));
    yield put(showToast({ type: 'error', message: error.message }))
  }
}

function* logout() {
  try {
    yield call(rsf.auth.signOut);
    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutFailure(err));
    yield put(showToast({ type: 'error', message: err.message }))
  }
}

function* loginStatusWatcher() {
  const channel = yield call(rsf.auth.channel)

  while (true) {
    const { user } = yield take(channel)
    if (user) yield put(loginSuccess(user))
    else yield put(logoutSuccess())
  }
}

function* userWatcher() {
  let task;
  while (true) {
    const { payload: user } = yield take(LOGIN_SUCCESS);
    const signingUp = yield select(({ Auth }) => Auth.signingUp);

    if (signingUp) {
      yield take(SIGNUP_SUCCESS);
    }

    task = yield fork(
      rsf.firestore.syncDocument,
      `users/${user.uid}`,
      {
        successActionCreator: syncUserSuccess,
      }
    )

    // Wait for the logout action, then stop sync
    yield take(LOGOUT_SUCCESS);
    if (task) yield cancel(task);
  }
}

function* requestEmailVerification() {
  try {
    yield call(rsf.auth.sendEmailVerification, {
      url: 'https://dths.bluecoltan.com',
      iOS: {
        bundleId: 'org.reactjs.native.example.DTHS',
      },
      android: {
        packageName: 'com.dhts',
        installApp: true,
        minimumVersion: '12',
      },
      handleCodeInApp: false,
    });
    yield put(requestEmailVerificationSuccess());
  } catch (err) {
    yield put(requestEmailVerificationFailure(err));
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(LOGOUT_START, logout),
    takeLatest(LOGIN_START, login),
    takeLatest(SIGNUP_START, signup),
    takeLatest(REQUEST_EMAIL_VERIFICATION_START, requestEmailVerification),
    fork(loginStatusWatcher),
    fork(userWatcher),
  ]);
}
