import React from 'react';

const Loader = ({ position }) => {
  const positionClass = position ? `position-${position}` : null;
  return (
    <div className={`loader-wrapper ${positionClass}`} >
      <div className="loader bg-white">
        <div className="whirly-loader"> </div>
      </div>
    </div>
  );
};

export default Loader;