import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-date-picker'
import Select from 'react-select';
import { updateUser } from 'redux/actions/user.action';
import Loader from 'components/common/loader';
import { showToast } from 'redux/actions/toast.action';

const PersonalInfoStep = () => {
  const user = useSelector(({ Auth }) => Auth.user);
  const loading = useSelector(({ Auth }) => Auth.updatingUser);
  const [birthday, setBirthday] = useState(user.birthday ? user.birthday.toDate() : '');
  const [baptismDate, setBaptismDate] = useState(user.baptismDate ? user.baptismDate.toDate() : '');
  const [gender, setGender] = useState(user.gender && { label: user.gender, value: user.gender });
  const dispatch = useDispatch();

  const canContinue = () => {
    if (!gender || !birthday || !baptismDate) return false;

    return true
  }

  const isUnderAge = () => (new Date()).getFullYear() - birthday.getFullYear() < 18;

  const submit = () => {
    if (!canContinue()) {
      return dispatch(showToast({
        type: 'warn',
        message: 'please fill all inputs',
      }));
    }
    if (isUnderAge()) {
      return dispatch(showToast({
        type: 'warn',
        message: 'you have to be above 18 years old',
      }));
    }
    const payload = {
      signupStep: 'step 2',
      gender: gender.value,
      birthday,
      baptismDate,
    }
    dispatch(updateUser(payload))
  }

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label className="col-form-label">Date of birth</label>
            <DatePicker
              className="date-form-control form-control"
              value={birthday}
              calendarIcon={null}
              clearIcon={null}
              onChange={setBirthday}
            />
          </div>
          <div className="form-group">
            <label className="col-form-label">BaptismDate day</label>
            <DatePicker
              className="date-form-control form-control"
              value={baptismDate}
              calendarIcon={null}
              clearIcon={null}
              onChange={setBaptismDate}
            />
          </div>
          <div className="form-group">
            <label className="col-form-label">gender</label>
            <Select
              value={gender}
              onChange={setGender}
              options={[
                { value: 'Woman', label: 'Woman' },
                { value: 'Man', label: 'Man' },
              ]}
            />
          </div>
          <div className="form-row">
            <div className="col-12">
              <button disabled={!canContinue()} className="btn btn-primary btn-block" type="button" onClick={submit}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default PersonalInfoStep;
