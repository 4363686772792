import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
// import man from '../../../assets/images/blog/12.png';
import { LogOut } from 'react-feather';
import { logout } from 'redux/actions/auth.action';
import logo from '../../../assets/images/logo/transparentHQ.png';



const UserMenu = () => {
  const user = useSelector(({ Auth }) => Auth.user);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  }

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={user.profilePicture.fullURL || logo} alt="header-user" />
          {/* <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div> */}
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          {/* <li><a href="/users/userEdit"><User />Edit Profile</a></li> */}
          {/* <li><Link to="/connect"><Mail />Inbox</Link></li> */}
          <li ><Link to="#" onClick={handleLogout} ><LogOut /> Log out</Link></li>
        </ul>
      </li>
    </Fragment>
  );
};


export default UserMenu;