import React, { useEffect } from 'react';
import { Trash2 } from 'react-feather';
import { confirmAlert } from 'react-confirm-alert';
import Breadcrumb from '../common/breadcrumb';
import Datatable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserRequests,
  setUserRequest,
} from 'redux/actions/request.action';
import { useHistory } from 'react-router';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { replyUserRequest } from 'redux/actions/leader.action';
import { showToast } from 'redux/actions/toast.action';

export default function UserRequests() {
  const dispatch = useDispatch();
  const history = useHistory()
  const requests = useSelector(({ Request }) => Request.userRequests);
  const loading = useSelector(({ Request }) => Request.loading);

  const COLUMNS = [
    {
      name: 'First Name',
      selector: 'user.firstName',
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: 'user.lastName',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'user.email',
      sortable: true,
    },
    {
      name: 'Church',
      selector: 'user.church.name',
      sortable: true,
    },
    {
      name: 'Country',
      selector: 'user.country.name',
      sortable: true,
    },
    {
      cell: (row) => <button
        type="button"
        title="Delete"
        className="btn p-0 btn-icon"
        onClick={() =>
          confirmAlert({
            title: 'Confirm delete',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  dispatch(replyUserRequest({
                    requestRef: `requests/${row.id}`,
                    status: 'denied',
                  }, () => {
                    dispatch(getUserRequests());
                    dispatch(showToast({
                      type: 'info',
                      message: 'Request deleted.',
                    }))
                  }, false));
                }
              },
              {
                label: 'No',
              }
            ]
          })
        }
      >
        <Trash2 />
      </button>,
      button: true,
    },
  ];

  const setCurrentRequest = (request) => {
    dispatch(setUserRequest(request));
    history.push(`/user-requests/${request.id}`)
  }

  useEffect(() => {
    dispatch(getUserRequests());

    // return () => dispatch(setLeaderAndUserRequests([]))
  }, [dispatch]);

  // if (loading) return <Loader position="absolute"/>;

  return (
    <>
      <Breadcrumb title="User requests" />
      <Datatable
        progressPending={loading}
        data={requests}
        columns={COLUMNS}
        highlightOnHover={true}
        pointerOnHover={true}
        onRowClicked={setCurrentRequest}
      />
    </>
  )
}