import React from 'react';
import { useSelector } from 'react-redux';
import logo from 'assets/images/logo/icon.svg';
import PersonalInfoStep from './personalInfoStep';
import AboutStep from './aboutStep';
import { Redirect } from 'react-router';
import Loader from 'components/common/loader';
import QuestionsStep from './questionsStep';
import PhotosStep from './photosStep';
import ChooseLeaderStep from './chooseLeaderStep';
import ChooseChurchStep from './chooseChurchStep';

const SignUpSteps = () => {
  const user = useSelector(({ Auth }) => Auth.user)
  const logged = useSelector(({ Auth }) => Auth.logged)
  const loadingUser = useSelector(({ Auth }) => Auth.loadingUser)
  let stepComponent;

  if (user.signupStep === 'done') return <Redirect to='/' />;
  if (!logged) return <Redirect to='/login' />;

  if (loadingUser) {
    stepComponent = <Loader position='absolute' />;
  } else if (user.signupStep === 'step 1' && user.role === 'user') {
    stepComponent = <PersonalInfoStep />;
  } else if (user.signupStep === 'step 1' && user.role === 'leader') {
    stepComponent = <PhotosStep leader />;
  } else if (user.signupStep === 'step 2' && user.role === 'user') {
    stepComponent = <AboutStep />;
  } else if (user.signupStep === 'step 2' && user.role === 'leader') {
    stepComponent = <ChooseChurchStep />;
  } else if (user.signupStep === 'step 3') {
    stepComponent = <QuestionsStep />;
  } else if (user.signupStep === 'step 4') {
    stepComponent = <PhotosStep />;
  } else if (user.signupStep === 'step 5') {
    stepComponent = <ChooseLeaderStep />;
  } else {
    stepComponent = <PersonalInfoStep />;
  }

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="authentication-main py-0">
          <div className="row">
            <div className="col-sm-12 p-0">
              <div className="auth-innerright align-items-start pt-5">
                <div className="authentication-box w-75" style={{ maxWidth: '600px' }}>
                  <div className="text-center w-50 m-auto"><img src={logo} className="w-25" alt="" /></div>
                  <div className="card mt-4 p-4" style={{ minHeight: '350px' }}>
                    {stepComponent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpSteps;
