import React, { useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Datatable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux';
import { getLeaderRequests, setLeaderRequest } from 'redux/actions/request.action';
import Loader from 'components/common/loader';
import { useHistory } from 'react-router';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ApprovedLeaders from './approvedLeaders';

const COLUMNS = [
  {
    name: 'First Name',
    selector: 'leader.firstName',
    sortable: true,
  },
  {
    name: 'Last Name',
    selector: 'leader.lastName',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'leader.email',
    sortable: true,
  },
  {
    name: 'Church',
    selector: (row) => row.church?.name || 'Error Loading church',
    sortable: true,
  },
  {
    name: 'Country',
    selector: 'leader.country.name',
    sortable: true,
  },
];

export default function LeaderRequests(props) {
  const dispatch = useDispatch();
  const history = useHistory()
  const requests = useSelector(({ Request }) => Request.all);
  const loading = useSelector(({ Request }) => Request.loading);
  const [activeTab, setActiveTab] = useState('1');

  const setCurrentRequest = (request) => {
    dispatch(setLeaderRequest(request));
    history.push(`leader-requests/${request.id}`)
  }

  useEffect(() => {
    dispatch(getLeaderRequests());
  }, [dispatch])

  return (
    <>
      <Breadcrumb title="Leader requests" />
      <Nav tabs className="borderb-tab-primary">
        <NavItem style={{cursor: 'pointer'}} className="nav nav-tabs" id="pending-tab" role="tablist">
          <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
            <i className="icon-target"></i>Pending
          </NavLink>
        </NavItem>
        <NavItem style={{cursor: 'pointer'}} className="nav nav-tabs" id="verified-tab" role="tablist">
          <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
            <i className="icon-image"></i>Verified
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Datatable
            progressPending={loading}
            data={requests}
            columns={COLUMNS}
            highlightOnHover={true}
            pointerOnHover={true}
            onRowClicked={setCurrentRequest}
          />
        </TabPane>
        <TabPane tabId="2">
          <div style={{ position: 'relative', minHeight: '400px' }}>
            <ApprovedLeaders />
          </div>
        </TabPane>
      </TabContent>
    </>
  )
}