import React, { useEffect, useState } from 'react';
import Datatable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import { getLeaders, setCurrentLeader as setLeader } from 'redux/actions/leader.action';

const COLUMNS = [
  {
    name: 'Name',
    selector: (leader) => `${leader.firstName || ''} ${leader.lastName || ''}`.trim(),
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Church',
    selector: (leader) => leader.church?.name || 'Error Loading church',
    sortable: true,
  },
  {
    name: 'Country',
    selector: (leader) => leader.country?.name || 'Error Loading church',
    sortable: true,
  },
];

export default function ApprovedLeaders() {
  const dispatch = useDispatch();
  const history = useHistory();
  const leaders = useSelector(({ Leader }) => Leader.all);
  const loading = useSelector(({ Leader }) => Leader.loading);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredData, setFilteredData] = useState([])
  const setCurrentLeader = (leader) => {
    dispatch(setLeader(leader));
    history.push(`leaders/${leader.id}`);
  }

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex">
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
          </div>
          <div>
            <Button onClick={() => history.push(`leaders/new`)}>New Leader</Button>
          </div>
        </div>
      </>
    )
  }, [filterText, resetPaginationToggle, history]);

  useEffect(() => {
    if (!filterText) return;

    const filteredRegx = new RegExp(filterText, 'i')
    const data = leaders.filter(leader => filteredRegx.test(`${leader.firstName} ${leader.lastName} ${leader.country?.name || ''} ${leader.church?.name || ''} ${leader.email}`));
    setFilteredData(data);
  }, [filterText, leaders]);

  useEffect(() => {
    if (leaders.length === 0)
      dispatch(getLeaders());
    // eslint-disable-next-line
  }, [dispatch]);

  // if (loading) return <Loader position="absolute" />;

  return (
    <Datatable
      progressPending={loading}
      persistTableHead
      highlightOnHover
      pointerOnHover
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      pagination
      paginationResetDefaultPage={resetPaginationToggle}
      data={filterText ? filteredData : leaders}
      columns={COLUMNS}
      onRowClicked={setCurrentLeader}
    />
  )
}

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;


const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={filterText} onChange={onFilter} />
    {
      filterText &&
      <ClearButton type="button" onClick={onClear}>X</ClearButton>
    }
    
  </>
);
