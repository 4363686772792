import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nextUser, like } from 'redux/actions/discover.action'
import Loader from 'components/common/loader';
import UserShow from 'components/users/userShow';
import Breadcrumb from 'components/common/breadcrumb';

const Discover = () => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const user = useSelector(({ Discover }) => Discover.currentUser);
  const loading = useSelector(({ Discover }) => Discover.loading);
  const nextUserLoading = useSelector(({ Discover }) => Discover.nextUserLoading);
  // const discoverID = useSelector(({ Auth }) => Auth.user.currentDiscoverID);

  const handleNext = useCallback(() => {
    dispatch(nextUser())
  }, [dispatch]);

  const handleLike = useCallback(() => {
    dispatch(like())
  }, [dispatch]);

  useEffect(() => {
    handleNext()
  }, [handleNext]);

  if (loading || nextUserLoading) {
    if (mounted) setMounted(false);
    return <Loader position='absolute' />;
  }

  const photos = [user.profilePicture, ...user.photos];

  // console.log({ currentUser: user.id, discoverID })

  const actions = (
    <div className="mt-auto align-self-end">
      <button
        className="btn btn-primary-gradien m-r-10"
        type="button"
        data-original-title="btn btn-info-gradien"
        title=""
        onClick={handleLike}
      >
        Like
      </button>
      <button
        className="btn btn-success-gradien m-r-10"
        type="button"
        data-original-title="btn btn-info-gradien"
        title=""
        onClick={handleNext}
      >
        Next
      </button>
    </div>
  )

  return (
    <>
    <Breadcrumb title={`${user.firstName} ${user.lastName}`} parent="Discover" />
    <UserShow
      actions={actions}
      photos={photos}
      user={user}
    />
    </>
  );
}

export default Discover
