import {
  GET_LEADER_REQUESTS_START,
  GET_LEADER_REQUESTS_FAILURE,
  GET_LEADER_REQUESTS_SUCCESS,
  SET_LEADER_REQUEST_START,
  GET_LEADER_REQUEST_START,
  GET_LEADER_REQUEST_SUCCESS,
  GET_LEADER_REQUEST_FAILURE,
  REPLY_LEADER_REQUEST_START,
  REPLY_LEADER_REQUEST_SUCCESS,
  REPLY_LEADER_REQUEST_FAILURE,
  GET_USER_REQUESTS_START,
  GET_USER_REQUESTS_SUCCESS,
  GET_USER_REQUESTS_FAILURE,
  GET_USER_REQUEST_START,
  GET_USER_REQUEST_SUCCESS,
  GET_USER_REQUEST_FAILURE,
  SET_USER_REQUEST_START,
  SET_LEADER_AND_USER_REQUESTS,
} from '../actionTypes';

const initial_state = {
  loading: true,
  all: [],
  userRequests: [],
  err: null,
  current: null,
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case GET_LEADER_REQUESTS_START:
      return { ...state, loading: true }
    case GET_LEADER_REQUESTS_SUCCESS:
      return { ...state, loading: false, all: action.payload }
    case GET_LEADER_REQUESTS_FAILURE:
      return { ...state, loading: false, err: action.payload }

    case SET_LEADER_REQUEST_START:
      return { ...state, loading: !action.payload, current: action.payload }

    case GET_LEADER_REQUEST_START:
      return { ...state, loading: true }
    case GET_LEADER_REQUEST_SUCCESS:
      return { ...state, loading: false, current: action.payload }
    case GET_LEADER_REQUEST_FAILURE:
      return { ...state, loading: false, err: action.payload }

    case REPLY_LEADER_REQUEST_START:
      return { ...state, loading: true }
    case REPLY_LEADER_REQUEST_SUCCESS:
      return { ...state, loading: true }
    case REPLY_LEADER_REQUEST_FAILURE:
      return { ...state, loading: false, err: action.payload }

    case GET_USER_REQUESTS_START:
      return { ...state, loading: true }
    case GET_USER_REQUESTS_SUCCESS:
      return { ...state, loading: false, userRequests: action.payload }
    case GET_USER_REQUESTS_FAILURE:
      return { ...state, loading: false, err: action.payload }

    case GET_USER_REQUEST_START:
      return { ...state, loading: true }
    case GET_USER_REQUEST_SUCCESS:
      return { ...state, loading: false, current: action.payload }
    case GET_USER_REQUEST_FAILURE:
      return { ...state, loading: false, err: action.payload }

    case SET_USER_REQUEST_START:
      return { ...state, loading: !action.payload, current: action.payload }

    case SET_LEADER_AND_USER_REQUESTS:
      return { ...state, loading: true, all: [], userRequests: [] }

    default: return { ...state };
  }
}
