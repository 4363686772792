import { all } from "redux-saga/effects";
import authSaga from './auth.saga';
import chatSaga from './chat.saga';
import toastSaga from './toast.saga';
import userSaga from './user.saga';
import questionSaga from './question.saga';
import countrySaga from './country.saga';
import churchSaga from './church.saga';
import leaderSaga from './leader.saga';
import messagingSaga from './messaging.saga';
import discoverSaga from './discover.saga';
import uploadSaga from './upload.saga';
import requestsSaga from './requests.saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    chatSaga(),
    toastSaga(),
    userSaga(),
    questionSaga(),
    countrySaga(),
    churchSaga(),
    leaderSaga(),
    messagingSaga(),
    discoverSaga(),
    uploadSaga(),
    requestsSaga(),
  ]);
}
