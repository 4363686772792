import rsf, { storage } from 'redux/rsf';
import {
  UPLOAD_PROFILE_IMAGE_START,
} from 'redux/actionTypes';
import { takeEvery, all, select, put, call } from 'redux-saga/effects';
import {
  uploadProfileImageFailure,
  uploadProfileImageSuccess,
} from 'redux/actions/upload.action';
import { updateUser } from 'redux/actions/user.action';

function* uploadProfileImage({ payload }) {
  const userId = yield select(({ Auth }) => Auth.user.id)
  const storageRef = storage.ref(`users/${userId}/${payload.file.name}-${Math.floor(new Date() / 1000)}`);
  const upload = rsf.storage.uploadFile(storageRef, payload.file);

  // const channel = eventChannel(emit => task.on('state_changed', emit));

  // yield takeEvery(channel, function (item) { console.log('item', item) });

  // Wait for upload to complete
  const { state, ref } = yield upload;

  if (state === 'success') {
    const url = yield call(rsf.storage.getDownloadURL, ref);
    yield put(uploadProfileImageSuccess({ url }));

    const photos = [{
      caption: '',
      url: '',
      fullURL: url,
    }];
    yield put(updateUser({ photos }));
  } else {
    yield put(uploadProfileImageFailure())
  }
}

export default function* authSaga() {
  yield all([
    takeEvery(UPLOAD_PROFILE_IMAGE_START, uploadProfileImage),
  ]);
}
