import React, { useEffect, useCallback, useState } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from 'reactstrap';
import Loader from 'components/common/loader';
import UserShow from 'components/users/userShow';
import { getRequest, getUser } from 'redux/actions/user.action';
import { replyUserRequest } from 'redux/actions/leader.action';
import Footer from 'components/common/footer';


const RequestShow = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ User }) => User.request.user);
  const loading = useSelector(({ User }) => User.loading);
  const request = useSelector(({ User }) => User.request);
  const requestError = useSelector(({ User }) => User.requestError);
  const [modalActive, setModalActive] = useState(false);
  const requestId = params.requestId
  const [response, setResponse] = useState('');
  const token = queryString.parseUrl(window.location.href).query.token

  const toggleModal = useCallback(() => {
    setModalActive(!modalActive)
  }, [modalActive])

  const handleApprove = useCallback(() => {
    const payload = {
      requestRef: `requests/${requestId}`,
      status: 'active',
    }
    dispatch(replyUserRequest(payload));
    setResponse('verified!')
    toggleModal();
  }, [dispatch, requestId, toggleModal]);

  const handleDecline = useCallback(() => {
    const payload = {
      requestRef: `requests/${requestId}`,
      status: 'denied',
    }
    dispatch(replyUserRequest(payload));
    setResponse('denied')
    toggleModal();
  }, [dispatch, requestId, toggleModal]);

  useEffect(() => {
    dispatch(getRequest(requestId))
  }, [requestId, dispatch])

  useEffect(() => {
    dispatch(getUser(requestId))
  }, [requestId, dispatch])

  const ErrorComponent = () => <Container className="h-100 position-absolute absolute-stretch">
    <Row className="h-100">
      <Col className="justify-content-center h-100 align-items-center d-flex">
        <h2 className="text-center">This request is not valid.</h2>
      </Col>
    </Row>
  </Container>

  if (loading) return <Loader />
  if (atob(token) !== requestId || requestError || !user?.id) return <ErrorComponent />;

  const photos = [user.profilePicture, ...user.photos];

  const actions = (
    <div className="mt-auto align-self-end">
      {
        request.status === 'pending' && response === '' ?
          <>
            <button
              className="btn btn-success m-r-10"
              type="button"
              data-original-title="btn btn-info-gradien"
              title=""
              onClick={handleApprove}
            >
              Verify
            </button>
            <button
              className="btn btn-danger m-r-10"
              type="button"
              data-original-title="btn btn-info-gradien"
              title=""
              onClick={handleDecline}
            >
              Deny
            </button>
          </> : null
      }
    </div>
  )

  return (
    <>
      {request.status !== 'pending' && (
        <h5 className="text-center mt-0" style={{ verticalAlign: 'middle' }}>
          <br />
          This request has already been answered
        </h5>
      )}
      <h3 className="text-center mt-3">
        {user.firstName} {user.lastName}
      </h3>
      <UserShow
        actions={actions}
        photos={photos}
        user={user}
      />
      <Footer />
      <Modal centered={true} isOpen={modalActive} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          request {response}
        </ModalHeader>
        <ModalBody>
          {
            response === 'denied' ?
              <span>
                {user.firstName} {user.lastName}'s request has been denied.
              </span> :
              <span>
                {user.firstName} {user.lastName} is now verified!
              </span>
          }
          <br />
          You can close this page now.
        </ModalBody>
      </Modal>
    </>
  );
}

export default RequestShow
