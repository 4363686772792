import firebase from 'firebase/app';
import { put, call, takeLatest, all, fork, take, cancel, select, takeEvery } from 'redux-saga/effects';
import rsf, { db } from 'redux/rsf';
import {
  getFriendsFailure,
  getFriendsSuccess,
  getChannelSuccess,
  getChannelFailure,
  sendMessageFailure,
  sendMessageSuccess,
  syncChannelsProcess,
} from "../actions/chat.action";
import {
  SYNC_CHANNELS_START,
  SYNC_CHANNELS_SUCCESS,
  LOGOUT_SUCCESS,
  GET_CHANNEL_START,
  STOP_SYNC_CHANNEL,
  SEND_MESSAGE_START,
  SYNC_CHANNELS_PROCESS,
} from '../actionTypes';

function transformChannels(results) {
  const channels = {};
  results.forEach(doc => {
    channels[doc.id] = { data: doc.data(), id: doc.id }
  });
  return channels;
}

function* getFriends() {
  try {
    const channels = yield select(({ Chat }) => Chat.channels);
    const user = yield select(({ Auth }) => Auth.user);
    const friendsID = Object.entries(channels).map(([channelID, channel]) => channel.data.users.find(friendID => friendID !== user.id));
    const result = yield call(
      rsf.firestore.getCollection,
      db.collection('users').where(firebase.firestore.FieldPath.documentId(), 'in', friendsID)
    );
    const friends = [];
    result.forEach(doc => friends.push(doc.data()));

    friends.forEach(friend => {
      const channel = Object.entries(channels).find(([channelID, channel]) => channel.data.users.find(id => id === friend.id));
      if (channel[0]) {
        channels[channel[0]].friend = friend;
      }
    });
    yield put(getFriendsSuccess(friends, channels));
  } catch (err) {
    yield put(getFriendsFailure(err))
  }
}

function* channelsWatcher() {
  while (true) {
    yield take(SYNC_CHANNELS_START);
    const userID = yield select(({ Auth }) => Auth.user.id);

    let task = yield fork(
      rsf.firestore.syncCollection,
      db.collection('channels').where('users', 'array-contains', userID),
      {
        successActionCreator: syncChannelsProcess,
        transform: transformChannels,
      }
    );

    // Wait for the logout action, then stop sync
    yield take(LOGOUT_SUCCESS)
    yield cancel(task)
  }
}

function intoChunks(list, howMany) {
  const result = [];
  const input = [...list];
  while (input[0])
    result.push(input.splice(0, howMany));
  return result;
}

function* syncChannelsProcessSaga({ payload: channels }) {
  const user = yield select(({ Auth }) => Auth.user);
  let friendsID = Object.entries(channels).map(([channelID, channel]) => {
    return channel.data.users.find(friendID => friendID !== user.id);
  });

  if (!friendsID) return;

  friendsID = intoChunks(friendsID, 10);

  const requests = friendsID.map(ids => call(
    rsf.firestore.getCollection,
    db.collection('users').where(firebase.firestore.FieldPath.documentId(), 'in', ids)
  ));
  let result = yield all(requests);

  const friends = [];
  result.forEach(query => query.forEach(doc => friends.push(doc.data())));

  friends.forEach(friend => {
    const channel = Object.entries(channels).find(([channelID, channel]) => channel.data.users.find(id => id === friend.id));
    if (channel[0]) {
      channels[channel[0]].friend = friend;
    }
  });
  yield put(getFriendsSuccess(friends, channels));
}

function* channelWatcher() {
  while (true) {
    const action = yield take(GET_CHANNEL_START);

    let task = yield fork(
      rsf.firestore.syncCollection,
      db.collection(`channels/${action.payload.id}/threads`).orderBy('created'),
      {
        successActionCreator: getChannelSuccess,
        failureActionCreator: getChannelFailure,
        transform: results => results.docs.map(doc => ({ ...doc.data(), id: doc.id }))
      }
    );

    // Wait for the logout or stop sync action, then stop sync
    yield take([LOGOUT_SUCCESS, STOP_SYNC_CHANNEL]);
    yield cancel(task)
  }
}

function* sendMessage({ payload }) {
  try {
    const { message, channel } = payload;
    message.created = new Date()
    yield call(
      rsf.firestore.addDocument,
      `channels/${channel.id}/threads/`,
      message
    );
    yield put(sendMessageSuccess(message, channel))
  } catch (err) {
    yield put(sendMessageFailure(err))
  }
}

export default function* authSaga() {
  yield all([
    fork(channelsWatcher),
    fork(channelWatcher),
    takeLatest(SYNC_CHANNELS_SUCCESS, getFriends),
    takeEvery(SEND_MESSAGE_START, sendMessage),
    takeEvery(SYNC_CHANNELS_PROCESS, syncChannelsProcessSaga)
  ]);
}
