import {
  UPLOAD_PROFILE_IMAGE_START,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
  UPLOAD_PROFILE_IMAGE_FAILURE,
} from 'redux/actionTypes';

export const uploadProfileImage = (payload) => {
  return { type: UPLOAD_PROFILE_IMAGE_START, payload }
}
export const uploadProfileImageSuccess = (payload) => {
  return { type: UPLOAD_PROFILE_IMAGE_SUCCESS, payload }
}
export const uploadProfileImageFailure = () => {
  return { type: UPLOAD_PROFILE_IMAGE_FAILURE }
}