import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import Loader from 'components/common/loader';
import { showToast } from 'redux/actions/toast.action';
import { getCountries, getStates } from 'redux/actions/country.action';
import { getChurches } from 'redux/actions/church.action';
import { getLeadersFromChurch, leaderRequestAdmin } from 'redux/actions/leader.action';

const ChooseChurchStep = () => {
  // const user = useSelector(({ Auth }) => Auth.user);
  const loading = useSelector(({ Auth }) => Auth.updatingUser);
  const countries = useSelector(({ Country }) => Country.all);
  // const loadingCountries = useSelector(({ Country }) => Country.all);
  const states = useSelector(({ Country }) => Country.states);
  const loadingStates = useSelector(({ Country }) => Country.loadingStates);
  const churches = useSelector(({ Church }) => Church.all);
  // const loadingChurches = useSelector(({ Church }) => Church.loading);
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [church, setChurch] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(getCountries());
    }
  }, [dispatch, countries])

  useEffect(() => {
    if (states.length === 0 && !loadingStates && countries.length > 0) {
      dispatch(getChurches(country.value));
    }
  }, [dispatch, loadingStates, states, country, countries])

  const submit = () => {
    if (!church.value) {
      return dispatch(showToast({
        type: 'warn',
        message: 'please choose a church',
      }));
    }
    const payload = {
      signupStep: 'done',
      churchID: church.value,
      stateID: state.value,
      countryID: country.value,
      validated: false,
    }
    dispatch(leaderRequestAdmin(payload));
  }

  const onCountryChange = (newCountry) => {
    dispatch(getStates(newCountry.value))
    setCountry(newCountry);
    setState({});
    setChurch({});
  }

  const onStateChange = (value) => {
    dispatch(getChurches(country.value, value.value));
    setState(value);
    setChurch({});
  }

  const onChurchChange = (value) => {
    dispatch(getLeadersFromChurch(value.value));
    setChurch(value);
  }

  const hasStates = () => country.value && !!states.length;

  const canChooseChurch = () => {
    return country.value && (!hasStates() || state.value);
  }

  const CountrySelect = ({ label = true, placeholder = false }) => (
    <div className="form-group">
      {
        label && <label className="col-form-label">Choose your country</label>
      }
      <Select
        placeholder={placeholder && 'Choose your country'}
        value={country.value && country}
        onChange={onCountryChange}
        options={countries.map(country => ({ label: country.name, value: country.iso }))}
      />
    </div>
  );

  const StateSelect = ({ label = true, placeholder = false }) => (
    <div className="form-group">
      {
        label &&
        <label className="col-form-label">Choose your state</label>
      }
      <Select
        placeholder={placeholder && 'Choose your state'}
        value={state.value && state}
        onChange={onStateChange}
        options={states.map(state => ({ label: state.name, value: state.stateCode }))}
      />
    </div>
  )

  const ChurchSelect = ({ label = true, placeholder = false }) => (
    <div className="form-group">
      {
        label &&
        <label className="col-form-label">Choose your church</label>
      }

      <Select
        placeholder={placeholder && 'Choose your church'}

        value={church.value && church}
        onChange={onChurchChange}
        options={churches.map(church => ({ label: church.name, value: church.id }))}
      />
    </div>
  )

  return (
    <Fragment>
      {loading && <Loader position='absolute' />}
      <div className="row">
        <div className="col-sm-12">
          <h5 className="display-5 text-center">
            Choose your church
          </h5>
          <CountrySelect />
          {hasStates() && <StateSelect />}
          {canChooseChurch() && <ChurchSelect />}
          <div className="form-row">
            <div className="col-12">
              <button disabled={!church.value} className="btn btn-primary btn-block" type="button" onClick={submit}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ChooseChurchStep;
