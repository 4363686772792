import {
  GET_LEADER_REQUESTS_START,
  GET_LEADER_REQUESTS_FAILURE,
  GET_LEADER_REQUESTS_SUCCESS,
  SET_LEADER_REQUEST_START,
  GET_LEADER_REQUEST_START,
  GET_LEADER_REQUEST_SUCCESS,
  GET_LEADER_REQUEST_FAILURE,
  REPLY_LEADER_REQUEST_START,
  REPLY_LEADER_REQUEST_SUCCESS,
  REPLY_LEADER_REQUEST_FAILURE,
  GET_USER_REQUESTS_START,
  GET_USER_REQUESTS_SUCCESS,
  GET_USER_REQUESTS_FAILURE,
  SET_USER_REQUEST_START,
  GET_USER_REQUEST_START,
  GET_USER_REQUEST_SUCCESS,
  GET_USER_REQUEST_FAILURE,
  SET_LEADER_AND_USER_REQUESTS,
  DELETE_USER_REQUEST_START,
DELETE_USER_REQUEST_SUCCESS,
DELETE_USER_REQUEST_FAILURE,
} from '../actionTypes';

export const getLeaderRequests = (payload) => ({
  type: GET_LEADER_REQUESTS_START,
  payload,
});

export const getLeaderRequestsSuccess = (payload) => ({
  type: GET_LEADER_REQUESTS_SUCCESS,
  payload,
});

export const getLeaderRequestsFailure = (err) => ({
  type: GET_LEADER_REQUESTS_FAILURE,
  payload: err,
});

export const getLeaderRequest = (payload) => ({
  type: GET_LEADER_REQUEST_START,
  payload,
});

export const getLeaderRequestSuccess = (payload) => ({
  type: GET_LEADER_REQUEST_SUCCESS,
  payload,
});

export const getLeaderRequestFailure = (err) => ({
  type: GET_LEADER_REQUEST_FAILURE,
  payload: err,
});

export const replyLeaderRequest = (payload) => ({
  type: REPLY_LEADER_REQUEST_START,
  payload,
});

export const replyLeaderRequestSuccess = (payload) => ({
  type: REPLY_LEADER_REQUEST_SUCCESS,
  payload,
});

export const replyLeaderRequestFailure = (err) => ({
  type: REPLY_LEADER_REQUEST_FAILURE,
  payload: err,
});

export const setLeaderRequest = (payload) => ({
  type: SET_LEADER_REQUEST_START,
  payload,
});


export const getUserRequests = (payload) => ({
  type: GET_USER_REQUESTS_START,
  payload,
});

export const getUserRequestsSuccess = (payload) => ({
  type: GET_USER_REQUESTS_SUCCESS,
  payload,
});

export const getUserRequestsFailure = (err) => ({
  type: GET_USER_REQUESTS_FAILURE,
  payload: err,
});


export const setUserRequest = (payload) => ({
  type: SET_USER_REQUEST_START,
  payload,
});

export const getUserRequest = (payload) => ({
  type: GET_USER_REQUEST_START,
  payload,
});

export const getUserRequestSuccess = (payload) => ({
  type: GET_USER_REQUEST_SUCCESS,
  payload,
});

export const getUserRequestFailure = (err) => ({
  type: GET_USER_REQUEST_FAILURE,
  payload: err,
});

export const deleteUserRequest = (payload) => ({
  type: DELETE_USER_REQUEST_START,
  payload,
});

export const deleteUserRequestSuccess = (payload) => ({
  type: DELETE_USER_REQUEST_SUCCESS,
  payload,
});

export const deleteUserRequestFailure = (err) => ({
  type: DELETE_USER_REQUEST_FAILURE,
  payload: err,
});

export const setLeaderAndUserRequests = (payload) => ({
  type: SET_LEADER_AND_USER_REQUESTS,
  payload,
});


