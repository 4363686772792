import { all, call, put, race, take, select, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import rsf, { db, fs } from 'redux/rsf';
import {
  GET_LEADERS_FROM_CHURCH_START,
  CHOOSE_LEADER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  USER_REQUEST_LEADER_START,
  LEADER_REQUEST_ADMIN_START,
  GET_REQUESTS_START,
  REPLY_USER_REQUEST_START,
  GET_LEADERS_START,
  GET_LEADER_START,
  SAVE_LEADER_START,
} from '../actionTypes';
import {
  getLeadersFromChurchSuccess,
  getLeadersFromChurchFailure,
  chooseLeaderSuccess,
  chooseLeaderFailure,
  userRequestLeaderSuccess,
  userRequestLeaderFailure,
  leaderRequestAdminSuccess,
  leaderRequestAdminFailure,
  getRequestsFailure,
  getRequestsSuccess,
  replyUserRequestSuccess,
  replyUserRequestFailure,
  getLeadersSuccess,
  getLeadersFailure,
  getLeaderSuccess,
  getLeaderFailure,
  saveLeaderFailure,
  saveLeaderSuccess,
} from 'redux/actions/leader.action';
import { updateUser } from 'redux/actions/user.action';
import { showToast } from 'redux/actions/toast.action';

function* getChurch(leader) {
  const church = yield call(rsf.firestore.getDocument, leader.church);
  leader.church = { ...church.data(), id: church?.id };
}
function* getCountry(leader) {
  const church = yield call(rsf.firestore.getDocument, leader.country);
  leader.country = church.data();
}

function* getLeaders() {
  try {
    const result = yield call(
      rsf.firestore.getCollection,
      db.collection('users').where('role', '==', 'leader').where('validated', '==', true)
    );

    const leaders = result.docs.map(doc => {
      const leader = doc.data();
      if (!leader.id) {
        leader.id = doc.id
      }
      return leader;
    });

    const promises = [];

    leaders.forEach(leader => {
      if (leader.church) {
        promises.push(getChurch(leader));
      }
      if (leader.country) {
        promises.push(getCountry(leader));
      }
    });

    yield all(promises);

    yield put(getLeadersSuccess({ leaders }));
  } catch (err) {
    yield put(getLeadersFailure(err));
  }
}

function* getLeader({ payload }) {
  try {
    const result = yield call(
      rsf.firestore.getDocument,
      db.doc(`users/${payload.id}`)
    );

    const leader = result.data();
    if (!leader.id) {
      leader.id = result.id
    }

    const promises = [];

    if (leader.church) {
      promises.push(getChurch(leader));
    }
    if (leader.country) {
      promises.push(getCountry(leader));
    }

    yield all(promises);

    yield put(getLeaderSuccess(leader));
  } catch (err) {
    yield put(getLeaderFailure(err));
  }
}

function* getLeadersFromChurch({ payload }) {
  try {
    const { churchID } = payload;
    const churchRef = db.doc(`churches/${churchID}`);
    const [leaderDocs, validatedLeaderDocs] = yield all([
      call(
        rsf.firestore.getCollection,
        db.collection('users').where('role', '==', 'leader').where('church', '==', churchRef)
      ),
      call(
        rsf.firestore.getCollection,
        db.collection('validatedLeaders').where('church', '==', churchRef)
      ),
    ]);
    const result = [
      ...leaderDocs.docs,
      ...validatedLeaderDocs.docs
    ].flat();

    const leaders = result.map(doc => {
      const leader = doc.data();
      leader._parent = doc.ref.parent.id
      if (!leader.id) {
        leader.id = doc.id
      }
      return leader;
    });
    yield put(getLeadersFromChurchSuccess({ leaders }));
  } catch (err) {
    yield put(getLeadersFromChurchFailure(err));
  }
}

function* userRequestLeader({ payload }) {
  try {
    const userID = yield select(({ Auth }) => Auth.user.id);

    yield put(updateUser({ signupStep: 'done' }));
    const userUpdateResult = yield race({
      success: take(UPDATE_USER_SUCCESS),
      failure: take(UPDATE_USER_FAILURE),
    });

    if (userUpdateResult.failure) {
      throw new Error('could not set user leader');
    }

    const request = yield call(
      rsf.firestore.addDocument,
      db.collection('requests'),
      {
        ...payload,
        user: db.doc(`users/${userID}`),
        approvedData: null,
        status: 'pending',
      }
    );
    yield put(userRequestLeaderSuccess(request));
  } catch (err) {
    yield put(userRequestLeaderFailure(err));
  }
}

function* chooseLeader({ payload }) {
  try {
    const { churchID, stateID, countryID, leaderID, ...userPayload } = payload;
    let church, state, country, leader;
    if (churchID) {
      church = db.doc(`churches/${churchID}`);
      userPayload.church = church;
    }
    if (countryID) {
      country = db.doc(`countries/${countryID}`);
      userPayload.country = country;
    }
    if (stateID) {
      state = db.doc(`countries/${countryID}/states/${stateID}`);
      userPayload.state = state;
    }
    if (leaderID) {
      leader = db.doc(leaderID);
      userPayload.leader = leader;
    }

    yield put(updateUser(userPayload));
    const userUpdateResult = yield race({
      success: take(UPDATE_USER_SUCCESS),
      failure: take(UPDATE_USER_FAILURE),
    });

    if (userUpdateResult.failure) {
      throw new Error('could not set user leader');
    }

    const userID = yield select(({ Auth }) => Auth.user.id);

    yield call(
      rsf.firestore.addDocument,
      db.collection('requests'),
      {
        user: db.doc(`users/${userID}`),
        approvedData: null,
        leader,
      }
    );
    yield put(chooseLeaderSuccess());
  } catch (err) {
    yield put(chooseLeaderFailure(err));
  }
}

function* leaderRequestAdmin({ payload }) {
  try {
    const { churchID, stateID, countryID, ...userPayload } = payload;
    let church, state, country;
    if (churchID) {
      church = db.doc(`churches/${churchID}`);
      userPayload.church = church;
    }
    if (countryID) {
      country = db.doc(`countries/${countryID}`);
      userPayload.country = country;
    }
    if (stateID) {
      state = db.doc(`countries/${countryID}/states/${stateID}`);
      userPayload.state = state;
    }

    yield put(updateUser(userPayload));
    const userUpdateResult = yield race({
      success: take(UPDATE_USER_SUCCESS),
      failure: take(UPDATE_USER_FAILURE),
    });

    if (userUpdateResult.failure) {
      throw new Error('could not set user leader');
    }

    const userID = yield select(({ Auth }) => Auth.user.id);

    yield call(
      rsf.firestore.addDocument,
      db.collection('leaderRequests'),
      {
        leader: db.doc(`users/${userID}`),
        approvedData: null,
        status: 'pending',
        church,
      }
    );
    yield put(leaderRequestAdminSuccess());
  } catch (err) {
    yield put(leaderRequestAdminFailure(err));
  }
}

function* getRequests() {
  try {
    const userID = yield select(({ Auth }) => Auth.user.id);
    const userRef = db.doc(`users/${userID}`);

    const result = yield call(
      rsf.firestore.getCollection,
      db.collection('requests')
        .where('leader', '==', userRef)
        .where('status', '==', 'pending'),
    );
    const requests = result.docs.map(doc => ({ ...doc.data(), ref: doc.ref }));
    const usersResult = yield all(
      requests.map((request) => call(rsf.firestore.getDocument, request.user))
    );
    const users = requests.filter((request, index) => usersResult[index].exists).map((request, index) => ({ ...usersResult[index].data(), requestRef: request.ref }))
    yield put(getRequestsSuccess(users))
  } catch (err) {
    yield put(getRequestsFailure(err))
  }
}

function* replyUserRequest({ payload, onSuccess, toast=true }) {
  try {
    const attributes = payload.request || {};
    yield call(
      rsf.firestore.updateDocument,
      payload.requestRef,
      { status: payload.status, ...attributes }
    )
    yield put(replyUserRequestSuccess())
    if (onSuccess) onSuccess();
    if (toast) {
      toast.success("Successfully Updated!")
    }
  } catch (err) {
    yield put(replyUserRequestFailure(err))
  }
}

function* saveLeader({ payload, onSuccess }) {
  try {
    delete payload.country;
    payload.created = fs.FieldValue.serverTimestamp();
    if (payload.church) {
      payload.country = payload.church.country
      payload.church = db.doc(`churches/${payload.church.id}`);
    }

    if (!payload.id) {
      const user = yield call(rsf.firestore.getCollection, db.collection('users').where('email', '==', payload.email));
      if (user.docs.length > 0) throw new Error('Email already is use');
      yield call(rsf.firestore.setDocument, db.doc(`users/leader_${payload.email}`), { ...payload, id: `leader_${payload.email}` })
    } else {
      yield call(rsf.firestore.updateDocument, db.doc(`users/${payload.id}`), payload)
    }

    yield put(saveLeaderSuccess())
    yield put(showToast({
      type: 'info',
      message: 'Leader saved',
    }))
    if (onSuccess) {
      onSuccess()
    }
  } catch (err) {
    yield put(showToast({
      type: 'error',
      message: err.message,
    }))
    yield put(saveLeaderFailure(err))
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(GET_LEADERS_FROM_CHURCH_START, getLeadersFromChurch),
    takeLatest(CHOOSE_LEADER_START, chooseLeader),
    takeLatest(USER_REQUEST_LEADER_START, userRequestLeader),
    takeLatest(LEADER_REQUEST_ADMIN_START, leaderRequestAdmin),
    takeLatest(GET_REQUESTS_START, getRequests),
    takeLatest(REPLY_USER_REQUEST_START, replyUserRequest),
    takeLatest(GET_LEADERS_START, getLeaders),
    takeLatest(GET_LEADER_START, getLeader),
    takeLatest(SAVE_LEADER_START, saveLeader),
  ]);
}
