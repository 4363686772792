import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateUser } from 'redux/actions/user.action';
import Loader from 'components/common/loader';
import { getQuestions } from 'redux/actions/question.action';
import { showToast } from 'redux/actions/toast.action';

const AboutStep = () => {
  const user = useSelector(({ Auth }) => Auth.user);
  const loading = useSelector(({ Auth }) => Auth.updatingUser);
  const allQuestions = useSelector(({ Question }) => Question.all);
  const loadingQuestions = useSelector(({ Question }) => Question.loading);
  const [aboutMe, setAboutMe] = useState(user.aboutMe || '');
  const [question, setQuestion] = useState({ question: {}, answer: '' });
  const dispatch = useDispatch();

  const submit = () => {
    if (!aboutMe || !question.answer || !question.question.value) {
      return dispatch(showToast({
        type: 'warn',
        message: 'please fill all the information'
      }))
    }
    const payload = {
      signupStep: 'step 3',
      aboutMe,
      questions: [{ 
        question: question.question.value,
        response: question.answer,
      }],
    }
    dispatch(updateUser(payload))
  }

  useEffect(() => {
    if (allQuestions.length === 0) {
      dispatch(getQuestions())
    }
  }, [dispatch, allQuestions])

  return (
    <Fragment>
      {(loading || loadingQuestions) && <Loader position='absolute' />}
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label className="col-form-label">About me</label>
            <textarea
              className="form-control"
              value={aboutMe}
              type='text'
              onChange={e => setAboutMe(e.target.value)}
              placeholder='Tell us a little bit about you'
              style={{ resize: 'none' }}
            />
          </div>
          <div className="form-group">
            <label className="col-form-label">Answer questions about your interests</label>
            <Select
              value={question.question}
              onChange={(value) => setQuestion({ ...question, question: value })}
              options={allQuestions.map(value => ({ label: value, value }))}
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              value={question.answer}
              type='text'
              onChange={e => setQuestion({ ...question, answer: e.target.value })}
              placeholder='Your answer...'
              style={{ resize: 'none' }}
            />
          </div>
          <div className="form-row">
            <div className="col-12">
              <button className="btn btn-primary btn-block" type="button" onClick={submit}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AboutStep;
