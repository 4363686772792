import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Loader from 'components/common/loader';
import Breadcrumb from '../common/breadcrumb';
import { getLeader, saveLeader, setCurrentLeader as setLeader } from 'redux/actions/leader.action';
import { getChurches } from 'redux/actions/church.action';


const ApprovedLeaderShow = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const loading = useSelector(({ Leader }) => Leader.loading);
  const leader = useSelector(({ Leader }) => Leader.current);
  const churches = useSelector(({ Church }) => Church.all);
  const [currentLeader, setCurrentLeader] = useState(leader || {})
  const [newChurch, setNewChurch] = useState({})

  const { leaderId } = params;

  const save = () => {
    const payload = {
      ...leader,
      ...currentLeader,
    }
    if (newChurch.value) {
      payload.church = newChurch.value
    }
    dispatch(saveLeader(payload))
  }

  useEffect(() => {
    if (!leader) {
      dispatch(getLeader({ id: leaderId }))
    }
    dispatch(getChurches());
    return () => dispatch(setLeader(null));
    // eslint-disable-next-line
  }, [leaderId, dispatch]);

  useEffect(() => {
    if (leader) {
      setCurrentLeader(leader);
    }
  }, [leader]);

  useEffect(() => {
    if (leader && churches.length) {
      const church = churches.find(church => church.id === leader.church.id);
      setNewChurch({ label: church.name, value: church });
    }
  }, [churches, leader]);

  if (loading || !leader) return <Loader position="absolute" />;

  return (
    <>
      <Breadcrumb title={`${leader.firstName} ${leader.lastName}`} parent="Leaders" />
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                {(loading) && <Loader position='absolute' />}
                <div className="card-header">
                  <div className="card-options">
                    <a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a>
                  </div>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row mb-2">
                      <div className="col-auto">
                        <img className="img-70 rounded-circle" alt="" src={leader.profilePicture?.fullURL} />
                      </div>
                      <div className="col">
                        <h3 className="mb-1">{leader.firstName} {leader.lastName}</h3>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label">Email-Address</label>
                      <input className="form-control" placeholder={leader.email} readOnly />
                    </div>

                    <div className="form-group">
                      <label className="form-label">Country</label>
                      <input className="form-control" placeholder={leader.country.name} readOnly />
                    </div>

                    <div className="form-group">
                      <label className="form-label">{leader.onlyLeaderData ? 'leader not registered on app' : 'Leader registered on app'}</label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <form className="card">
                {loading && <Loader position='absolute' />}
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">Status</label>
                        <Select
                          className="mt-4 mb-2"
                          styles={{ margin: "25px 0 15px 0" }}
                          value={{ label: currentLeader.validated ? 'active' : 'inactive', value: currentLeader.validated }}
                          onChange={({ value }) => setCurrentLeader({ ...currentLeader, validated: value })}
                          options={[{ label: 'active', value: true }, { label: 'inactive', value: false }]}
                        />
                      </div>
                      <div className="form-group mt-0">
                        <label className="form-label">Church</label>
                        <Select
                          className="mt-4 mb-2"
                          styles={{ margin: "25px 0 15px 0" }}
                          value={newChurch}
                          onChange={(church) => setNewChurch(church)}
                          options={churches.map(church => ({ label: church.name, value: church }))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button onClick={save} className="btn btn-primary" type="button">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default ApprovedLeaderShow
