import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import RightSidebar from './common/right-sidebar';
import Footer from './common/footer';
import ThemeCustomizer from './common/theme-customizer'
import Loader from './common/loader';
import VerifyEmail from './auth/verifyEmail';
// import firebase from 'firebase/app'

const AppLayout = ({ children }) => {
  const logged = useSelector(({ Auth }) => Auth.logged);
  const loadingUser = useSelector(({ Auth }) => Auth.loadingUser);
  const userSteps = useSelector(({ Auth }) => Auth.user.signupStep);
  const role = useSelector(({ Auth }) => Auth.user.role);
  const emailVerified = useSelector(({ Auth }) => Auth.authInfo.emailVerified);

  if (!logged) return <Redirect to="/login" />;
  if (loadingUser) return <Loader />;
  if (role !== 'admin' && userSteps !== 'done') return <Redirect to="/signup-steps" />;

  // firebase.auth().currentUser.getIdToken().then(token => console.log(token))

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header />
          <Sidebar />
          <RightSidebar />
          <div className="page-body">
            {
              emailVerified ?
                children :
                <VerifyEmail />
            }
          </div>
          <Footer />
          <ThemeCustomizer />
        </div>
      </div>
    </div>
  );
}

export default AppLayout;