import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { storage } from '../../../redux/rsf';
// import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import start_conversion from '../../../assets/images/start-conversion.jpg';
import errorImg from '../../../assets/images/search-not-found.png';
import Breadcrumb from '../../common/breadcrumb';

import {
  getChannel,
  stopSyncChannel,
  sendMessage,
} from '../../../redux/actions/chat.action';
import Loader from '../../common/loader';

const Chat = ({ match }) => {
  const channels = useSelector(content => content.Chat.channels);
  const threads = useSelector(content => content.Chat.threads);
  const currentUser = useSelector(content => content.Auth.user);
  const loading = useSelector(content => content.Chat.loading);
  const loadingChannel = useSelector(content => content.Chat.loadingChannel);
  const dispatch = useDispatch();


  const currentChannel = channels[match.params.channelId];

  useEffect(() => {
    dispatch(stopSyncChannel());
    if (currentChannel) {
      dispatch(getChannel(currentChannel))
    }
  }, [currentChannel, dispatch]);

  useEffect(() => {
    if (!loading) {
      scrollToBottom(true);
    }
  }, [loading]);
  // eslint-disable-next-line
  const [searchKeyword, setSearchKeyword] = useState('');
  const [messageInput, setMessageInput] = useState('');
  // eslint-disable-next-line
  const [loadingFile, setLoadingFile] = useState(false);
  // eslint-disable-next-line
  const [uploadFileProgress, setUploadFileProgress] = useState(null);

  const scrollToBottom = (forced) => {
    const container = document.querySelector(".chat-history");
    if (!container) return;
    setTimeout(() => {
      if (forced) {
        container.scrollBy({ top: container.scrollHeight });
      } else {
        container.scrollBy({ top: container.scrollHeight, behavior: 'smooth' });
      }
    }, 300)
  }

  const handleMessageChange = message => setMessageInput(message);

  const handleMessagePress = (e, url) => {
    if (e.key === "Enter" || e === "send") {
      const message = {
        recipientFirstName: currentChannel.friend.firstName || null,
        recipientID: currentChannel.friend.id,
        recipientLastName: currentChannel.friend.lastName || null,
        recipientProfilePictureURL: currentChannel.friend.profilePicture.fullURL || null,
        senderFirstName: currentUser.firstName || null,
        senderID: currentUser.id,
        senderLastName: currentUser.lastName || null,
        senderProfilePictureURL: currentUser.profilePicture.fullURL || null,
      }

      if (url) {
        message.url = url;
      } else {
        if (messageInput.trim() === '') return;

        message.content = messageInput;
      }

      dispatch(sendMessage(message, currentChannel))
      setMessageInput('')
    }
  }

  const handleUploadStart = () => {
    setLoadingFile(true)
    setUploadFileProgress(0)
  };

  const handleProgress = progress => setUploadFileProgress(progress);

  const handleUploadError = error => {
    setLoadingFile(false);
    console.error(error);
  };

  const handleUploadSuccess = async filename => {
    setLoadingFile(false);
    setUploadFileProgress(100);
    const url = await storage
      .ref(`channels/${currentChannel.id}`)
      .child(filename)
      .getDownloadURL();
    handleMessagePress('send', url);
  };

  if (loading) {
    return <Loader />
  } 

  return (
    <div>
      <Breadcrumb title="Chat" parent="Chat" />
      <div className="container-fluid">
        <div className="row">
          <div className="col call-chat-sidebar col-sm-12">
            <div className="card">
              <div className="card-body chat-body">
                <div className="chat-box">
                  <div className="chat-left-aside">
                    <div className="media">
                      <img src={currentUser.profilePicture} className="rounded-circle user-image" alt="" />
                      <div className="about">
                        <div className="name f-w-600">{currentUser.firstName} {currentUser.lastName}</div>
                        <div className="status">
                          {currentUser.status}
                        </div>
                      </div>
                    </div>
                    <div className="people-list custom-scrollbar" id="people-list">
                      <div className="search">
                        <form className="theme-form">
                          <div className="form-group">
                            {/* <input
                                className="form-control"
                                type="text"
                                placeholder="search"
                                defaultValue={searchKeyword}
                                onChange={(e) => handleSearchKeyword(e.target.value)}
                              /> */}
                            {/* <i className="fa fa-search"></i> */}
                          </div>
                        </form>
                      </div>
                      <ul className="list">
                        {/* {searchKeyword !== '' ?
                          members.map(member => {
                            return (
                              // selectedUser.id === member.id ? 'active' : ''
                              <li className={`clearfix`}
                                key={member.id}>
                                <Link className="w-100 h-100" to={`/connect/${'do se'}`} >
                                  <img src={member.profilePicture} className="rounded-circle user-image" alt="" />
                                  <div className="status-circle offline"></div>
                                  <div className="about">
                                    <div className="name">{member.firstName} {member.lastName}</div>
                                    <div className="status">
                                      {member.status}
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            );
                          })
                          :
                          null
                        } */}

                        {searchKeyword === '' ? Object.entries(channels).map(([channelID, channel]) => {
                          return (
                            <li
                              className={`clearfix ${currentChannel && currentChannel.id === channelID ? 'active' : ''}`}
                              key={channelID}
                            >
                              <img src={channel.friend.profilePicture.fullURL} className="rounded-circle user-image" alt="" />
                              <div className={`status-circle ${channel.friend.online === true ? 'online' : 'offline'}`} ></div>
                              <div className="about">
                                <div className="name">
                                  {channel.friend.firstName} {channel.friend.lastName}
                                </div>
                                <div className="status">
                                  {/* {member.online ? "online" : "offline"} */}
                                </div>
                              </div>
                              <Link
                                style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}
                                to={`/connect/${channel.id}`}
                              >
                              </Link>
                            </li>
                          );
                        }) :
                          <img className="img-fluid" src={errorImg} alt="" />
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col call-chat-body">
            <div className="card">
              <div className="card-body p-0">
                <div className="row chat-box">
                  <div className="col pr-0 chat-right-aside">
                    <div className="chat">
                      <div className="chat-header clearfix">
                        {currentChannel ?
                          <>
                            <img src={currentChannel.friend.profilePicture.fullURL} className="rounded-circle" alt="" />
                            <div className="about">
                              <div className="name">
                                {currentChannel.friend.firstName} {currentChannel.friend.lastName}
                              </div>
                              <div className="status digits" >
                                {/* {selectedUser.online ? 'online' : selectedUser.lastSeenDate} */}
                                {currentChannel.friend.online ? 'online' : 'offline'}
                              </div>
                            </div>
                            <ul className="list-inline float-left float-sm-right chat-menu-icons">

                              {
                                !loadingChannel ?
                                  <li className="list-inline-item">
                                    <CustomUploadButton
                                      randomizeFilename
                                      accept="image/*"
                                      storageRef={storage.ref(`channels/${currentChannel.id}`)}
                                      onUploadStart={handleUploadStart}
                                      onUploadError={handleUploadError}
                                      onUploadSuccess={handleUploadSuccess}
                                      onProgress={handleProgress}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <i className="icon-clip" style={{ fontSize: '1.5rem' }}></i>

                                    </CustomUploadButton>
                                  </li>
                                  : null
                              }

                              {/* <li className="list-inline-item"><a href="#javascript"><i className="icon-search"></i></a></li> */}
                              {/* <li className="list-inline-item"><a href="#javascript"><i className="icon-headphone-alt"></i></a></li>
                            <li className="list-inline-item"><a href="#javascript"><i className="icon-video-camera"></i></a></li> */}
                              {/* <li className="list-inline-item toogle-bar" onClick={() => chatMenuToggle()}><a href="#javascript"><i className="icon-menu"></i></a></li> */}
                            </ul>
                          </> : null
                        }
                      </div>

                      <div className="chat-history chat-msg-box custom-scrollbar" >
                        {
                          loadingChannel ?
                            <Loader position="relative" />
                            :
                            <ul>
                              {threads.length > 0 ? threads.map((message, i) => {
                                return (
                                  <li key={message.id} className="clearfix">
                                    <div className={`message my-message ${message.senderID !== currentUser.id ? '' : 'float-right'}`}>
                                      <div className="rounded-circle d-inline-block" style={{ width: '2rem', height: '2rem', overflow: 'hidden' }}>
                                        <img
                                          src={message.senderProfilePictureURL}
                                          className={`${message.senderID !== currentUser.id ? 'float-left' : 'float-right'}`}
                                          style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                                          alt=""
                                        />
                                      </div>
                                      <div className="d-inline-block px-1">{message.senderFirstName} {message.senderLastName}</div>
                                      <div className="message-data text-right">
                                        <span className="message-data-time">{message.created.toDate().toDateString()}</span>
                                      </div>
                                      {
                                        message.url ?
                                          <div className="w-75" style={{ height: '280px' }}>
                                            <img
                                              className="w-100"
                                              style={{ objectFit: "fill" }}
                                              src={message.url}
                                              alt="file send from chat"
                                            />
                                          </div>
                                          :
                                          message.content
                                      }
                                    </div>
                                  </li>
                                );
                              }) :
                                <div>
                                  <img className="img-fluid" src={start_conversion} alt="start conversion " />
                                </div>
                              }
                            </ul>
                        }
                      </div>
                      <div className="chat-message clearfix">
                        <div className="row">
                          <div className="col-xl-12 d-flex">
                            {/* <div className="smiley-box bg-primary">
                                <div className="picker"><img src={require('../../../assets/images/smiley.png')} className="" alt="" /></div>
                              </div> */}
                            {
                              !loadingChannel && currentChannel ?
                                <div className="input-group text-box">
                                  <input
                                    type="text"
                                    className="form-control input-txt-bx"
                                    placeholder="Type a message......"
                                    value={messageInput}
                                    onKeyPress={(e) => handleMessagePress(e)}
                                    onChange={(e) => handleMessageChange(e.target.value)}
                                  />
                                  <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => handleMessagePress('send')} >SEND</button>
                                  </div>
                                </div>
                                : null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Chat;
