import React from 'react'
import { useSelector } from 'react-redux';

function WaitForValidation() {
  const role = useSelector(({ Auth }) => Auth.user.role);

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 'calc(100vh - 400px)' }}>
      <h3>
        waiting for validation from {role === 'leader' ? 'the admin' : 'your leader'}, we will notify you once approved
      </h3>
    </div>
  )
}

export default WaitForValidation;
