import React, { useEffect, Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import search from 'assets/images/search-not-found.png';
import userDemoPic from 'assets/images/user/user.png';
import { getRequests, replyUserRequest } from 'redux/actions/leader.action';
// import { deletedUser } from '../../../services/contact.service';

const UserList = (props) => {
  const dispatch = useDispatch();
  const initialUsers = useSelector(({ Leader }) => Leader.requests)
  const [users, setUsers] = useState(initialUsers);

  useEffect(() => {
    dispatch(getRequests());
  }, [dispatch]);

  useEffect(() => {
    setUsers(initialUsers);
  }, [initialUsers])

  const handleReplyRequest = (payload) => {
    dispatch(replyUserRequest(payload));
    setUsers(users.filter(user => user.id !== payload.user.id))
  }

  return (
    <Fragment>
      {/* <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-8">
              <form className="search-form contact-search">
                <div className="form-group mb-0">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="search"
                    onChange={(e) => handleSearchKeyword(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <div className="contact-filter justify-content-end">
                <button className="btn btn-primary ml-4" >
                  ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="product-wrapper-grid">
        <div className="row">
          {users.length > 0 ?
            <div className="col-sm-12">
              <div className="card">
                <div className="product-box table-responsive contact-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <h5 className="mb-0">
                            {/* profile picture */}
                          </h5>
                        </th>
                        <th>
                          <h5 className="mb-0">Name</h5>
                        </th>
                        <th colSpan="2">
                          <h5 className="mb-0">email</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      {users.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <img
                                className="img-60 rounded-circle"
                                src={user.profilePicture.fullURL ? user.profilePicture.fullURL : userDemoPic}
                                alt="#"
                              />
                            </td>
                            <td>
                              <h6>{user.firstName} {user.lastName}</h6>
                            </td>
                            <td>
                              <h6>{user.email}</h6>
                            </td>
                            <td>
                              <div className="d-flex justify-content-end">
                                <button
                                  className="btn btn-primary mr-2"
                                  onClick={() => handleReplyRequest({ requestRef: user.requestRef, status: 'active', user })}
                                >
                                  approve
                                </button>
                                <button
                                  className="btn btn-danger"
                                  onClick={() => handleReplyRequest({ requestRef: user.requestRef, status: 'denied', user })}
                                >
                                  deny
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            :
            <div className="col-sm-12">
              <div>
                <div className="search-not-found text-center">
                  <div>
                    <img src={search} alt="" className="second-search" />
                    <p className="mb-0">Sorry, you don't have any requests</p>
                  </div>
                </div>
              </div>
            </div>}
        </div>
      </div>
    </Fragment>
  );
};

export default UserList;