import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Redirect } from "react-router";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from 'formik';


import logo from 'assets/images/logo/icon.svg';
import Loader from 'components/common/loader';
import { login } from 'redux/actions/auth.action';

const SignIn = () => {
  const logged = useSelector(({ Auth }) => Auth.logged);
  const dispatch = useDispatch();

  const handleLogin = data => dispatch(login(data));

  if (logged) return <Redirect to={process.env.PUBLIC_URL} />

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          <div className="authentication-main py-0">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} className="w-25" alt="" /></div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>LOGIN</h4>
                          <h6>Enter your Email and Password </h6>
                        </div>
                        <Formik
                          initialValues={{ email: '', password: '' }}
                          onSubmit={handleLogin}
                        >
                          {({ isSubmitting }) => (
                            <Form className="theme-form" >
                              {(isSubmitting || logged) && <Loader />}
                              <div className="form-group">
                                <label className="col-form-label pt-0">Your Email</label>
                                <Field className="form-control" type="email" name="email" placeholder="Email address" />
                                {/* {errors.email && 'Email is required'} */}
                              </div>
                              <div className="form-group">
                                <label className="col-form-label">Password</label>
                                <Field className="form-control" type="password" name="password" placeholder="******" />
                                {/* {errors.password && 'Email is required'} */}
                              </div>
                              {/* <div className="checkbox p-0">
                                  <input id="checkbox1" type="checkbox" />
                                  <label htmlFor="checkbox1">Remember me</label>
                                </div> */}
                              <div className="form-group form-row mt-3 mb-0">
                                <button disabled={isSubmitting} className="btn btn-primary btn-block" type="submit" >Login</button>
                              </div>
                              {/* <div className="mt-3 text-right">
                                Are you a new user?,<Link to="/signup"> Sing up</Link>
                              </div> */}
                              {/* Uncomment for login with social networks */}
                              {/* <div className="login-divider"></div>
                                <div className="social mt-3">
                                  <div className="form-group btn-showcase d-flex">

                                    <button className="btn social-btn btn-fb d-inline-block" type="button" onClick={facebookAuth}> <i className="fa fa-facebook"></i></button>
                                    <button className="btn social-btn btn-twitter d-inline-block" type="button" onClick={googleAuth}><i className="fa fa-google"></i></button>
                                    <button className="btn social-btn btn-google d-inline-block" type="button" onClick={twitterAuth}><i className="fa fa-twitter"></i></button>
                                    <button className="btn social-btn btn-github d-inline-block" type="button" onClick={githubAuth}><i className="fa fa-github"></i></button>
                                  </div>
                                </div> */}
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div >
  );
};

export default withRouter(SignIn);
