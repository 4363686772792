import {
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGIN_START,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  SYNC_USER_SUCCESS,
  SYNC_USER_FAILURE,
  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  REQUEST_EMAIL_VERIFICATION_START,
  REQUEST_EMAIL_VERIFICATION_SUCCESS,
  REQUEST_EMAIL_VERIFICATION_FAILURE,
} from '../actionTypes';

const initial_state = {
  user: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    baptismDate: null,
    birthday: null,
    gender: null,
    role: null,
    active: null,
    online: null,
    pushToken: null,
    city: null,
    state: null,
    aboutMe: null,
    church: null,
    position: null,
    photos: [],
    interests: [],
    questions: [],
    ref: false,
    profilePicture: {},
    verified: false,
    subscribed: false,
    signupStep: null,
  },
  loadingAuth: true,
  loadingUser: true,
  signingUp: false,
  logged: false,
  error: null,
  updatingUser: false,
  authInfo: {},
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case LOGIN_START:
      return { ...state, loadingAuth: true, loadingUser: true, logged: false }
    case LOGIN_SUCCESS:
      return { ...state, loadingAuth: false, logged: true, authInfo: { ...action.payload.toJSON(), ref: action.payload } }
    case LOGIN_FAILURE:
      return { ...state, loadingAuth: false, logged: false, loadingUser: false }

    case LOGOUT_START:
      return { ...state }
    case LOGOUT_SUCCESS:
      return { ...initial_state, loadingAuth: false, loadingUser: false }
    case LOGOUT_FAILURE:
      return { ...state }

    case SYNC_USER_SUCCESS:
      return { ...state, user: { ...state.user, ...action.payload }, loadingUser: false }
    case SYNC_USER_FAILURE:
      return { ...state, loadingUser: false, error: action.payload }

    case SIGNUP_START:
      return { ...state, loadingUser: true, logged: false, signingUp: true }
    case SIGNUP_SUCCESS:
      return { ...state, signingUp: false }
    case SIGNUP_FAILURE:
      return { ...state, logged: false, loadingUser: false, error: action.payload, signingUp: false }

    case UPDATE_USER_START:
      return { ...state, updatingUser: true }
    case UPDATE_USER_SUCCESS:
      return { ...state, updatingUser: false }
    case UPDATE_USER_FAILURE:
      return { ...state, updatingUser: false }

    case REQUEST_EMAIL_VERIFICATION_START:
      return { ...state, sendingEmailVerification: true }
    case REQUEST_EMAIL_VERIFICATION_SUCCESS:
      return { ...state, sendingEmailVerification: false }
    case REQUEST_EMAIL_VERIFICATION_FAILURE:
      return { ...state, sendingEmailVerification: false }
    default: return { ...state };
  }
}
