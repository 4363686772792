import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { syncChannels } from '../../redux/actions/chat.action';

const RightSidebar = () => {
  const dispatch = useDispatch();
  const channels = useSelector(({ Chat }) => Chat.channels);

  useEffect(() => {
    dispatch(syncChannels());
  }, [dispatch]);
  return (
    <div>
      <div className="right-sidebar" id="right_side_bar">
        <div className="container p-0">
          <div className="modal-header p-l-20 p-r-20">
            <div className="col-sm-8 p-0">
              <h6 className="modal-title font-weight-bold">FRIEND LIST</h6>
            </div>
            <div className="col-sm-4 text-right p-0"><i className="mr-2" data-feather="settings"></i></div>
          </div>
        </div>
        <div className="friend-list-search mt-0">
          <input type="text" placeholder="search friend" /><i className="fa fa-search"></i>
        </div>
        <div className="chat-box custom-scrollbar">
          <div className="people-list friend-list">
            <ul className="list">
              {Object.entries(channels).map(([channelID, channel]) => (
                <li className="clearfix" key={channelID} >
                  <Link to={`/connect/${channelID}`}>
                    <img className="rounded-circle user-image" src={channel.profilePicture} alt="" />
                    <div className={`status-circle ${channel.online && 'online'}`}></div>
                    <div className="about">
                      <div className="name">{channel.firstName} {channel.lastName}</div>
                      <div className="status"> {channel.online ? 'Online' : 'Offline'}</div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RightSidebar; 