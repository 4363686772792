import {
  DISCOVER_START,
  DISCOVER_SUCCESS,
  DISCOVER_FAILURE,
  NEXT_USER_START,
  NEXT_USER_SUCCESS,
  NEXT_USER_FAILURE,
  LIKE_START,
  LIKE_SUCCESS,
  LIKE_FAILURE,
} from "redux/actionTypes";

const initial_state = {
  users: [],
  loading: true,
  currentUser: null,
  nextUserLoading: true,
  likeLoading: false,
  likeError: null,
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case DISCOVER_START:
      return { ...state, loading: true }
    case DISCOVER_SUCCESS:
      return { ...state, loading: false, users: action.payload.users }
    case DISCOVER_FAILURE:
      return { ...state, loading: false }

    case NEXT_USER_START:
      return { ...state, nextUserLoading: true }
    case NEXT_USER_SUCCESS:
      return { ...state, nextUserLoading: false, currentUser: action.payload }
    case NEXT_USER_FAILURE:
      return { ...state, nextUserLoading: false }

    case LIKE_START:
      return { ...state, likeLoading: true }
    case LIKE_SUCCESS:
      return { ...state, likeLoading: false }
    case LIKE_FAILURE:
      return { ...state, likeLoading: false, likeError: action.payload }

    default: return { ...state };
  }
}
