import { SET_MESSAGING_TOKEN_START, SET_MESSAGING_TOKEN_FAILURE, SET_MESSAGING_TOKEN_SUCCESS } from "redux/actionTypes";

export const setMessagingToken = token => ({
  type: SET_MESSAGING_TOKEN_START,
  payload: token,
});

export const setMessagingTokenSuccess = () => ({
  type: SET_MESSAGING_TOKEN_SUCCESS,
});

export const setMessagingTokenFailure = err => ({
  type: SET_MESSAGING_TOKEN_FAILURE,
  payload: err,
});
