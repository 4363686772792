import {
  SYNC_CHANNELS_START,
  SYNC_CHANNELS_PROCESS,
  SYNC_CHANNELS_SUCCESS,
  SYNC_CHANNELS_FAILURE,
  GET_FRIENDS_START,
  GET_FRIENDS_SUCCESS,
  GET_FRIENDS_FAILURE,
  GET_CHANNEL_START,
  GET_CHANNEL_SUCCESS,
  GET_CHANNEL_FAILURE,
  STOP_SYNC_CHANNEL,
  SEND_MESSAGE_START,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
} from '../actionTypes';

export const syncChannels = () => ({
  type: SYNC_CHANNELS_START
});

export const syncChannelsProcess = (channels) => ({
  type: SYNC_CHANNELS_PROCESS,
  payload: channels,
});

export const syncChannelsSuccess = (channels) => ({
  type: SYNC_CHANNELS_SUCCESS,
  payload: channels,
});

export const syncChannelsFailure = (err) => ({
  type: SYNC_CHANNELS_FAILURE
});

export const getFriends = (payload) => ({
  type: GET_FRIENDS_START,
  payload,
});

export const getFriendsSuccess = (friends, channels) => ({
  type: GET_FRIENDS_SUCCESS,
  payload: {
    friends, channels
  },
});

export const getFriendsFailure = (err) => ({
  type: GET_FRIENDS_FAILURE,
  payload: err,
});

export const getChannel = (channel) => ({
  type: GET_CHANNEL_START,
  payload: channel,
});

export const getChannelSuccess = (threads) => ({
  type: GET_CHANNEL_SUCCESS,
  payload: threads,
});

export const getChannelFailure = (err) => ({
  type: GET_CHANNEL_FAILURE,
  payload: err
});

export const stopSyncChannel = () => ({
  type: STOP_SYNC_CHANNEL,
});

export const sendMessage = (message, channel) => ({
  type: SEND_MESSAGE_START,
  payload: { message, channel },
});

export const sendMessageSuccess = (message) => ({
  type: SEND_MESSAGE_SUCCESS,
  payload: message,
});

export const sendMessageFailure = (err) => ({
  type: SEND_MESSAGE_FAILURE,
  payload: err,
});

