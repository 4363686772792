/* TODO APP*/
export const GET_LIST = "GET_LIST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const MARK_ALL_ITEMS = "MARK_ALL_ITEMS";
export const ADD_NEW_ITEM = "ADD_NEW_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SELECTED_ITEM = "SELECTED_ITEM";

/* TODO FIREBASE APP*/
export const GET_LIST_FIREBASE = "GET_LIST";
export const GET_LIST_SUCCESS_TASK = "GET_LIST_SUCCESS_TASK";
export const MARK_ALL_TASK = "MARK_ALL_TASK";
export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const REMOVE_TASK = "REMOVE_TASK";
export const SELECTED_TASK = "SELECTED_TASK";

/* ECOMMERRCE APP */

export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const SELECTED_PRODUCT = "SELECTED_PRODUCT";
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY'
export const SEARCH_MEMBER_PRODUCT = 'SEARCH_MEMBER_PRODUCT';

// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREMENT_QTY = 'INCREMENT_QTY';
export const DECREMENT_QTY = 'DECREMENT_QTY';
export const GET_SINGLE_ITEM = "GET_SINGLE_ITEM";

// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// CheckOut Process
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

// FILTERS
export const FILTER_BRAND = 'FILTER_BRAND';
export const FILTER_COLOR = 'FILTER_COLOR';
export const FILTER_PRICE = 'FILTER_PRICE';
export const SORT_BY = 'SORT_BY';
export const SEARCH_BY = 'SEARCH_BY';

// CHAT
export const SYNC_CHANNELS_START = "SYNC_CHANNELS_START";
export const SYNC_CHANNELS_PROCESS = "SYNC_CHANNELS_PROCESS";
export const SYNC_CHANNELS_SUCCESS = "SYNC_CHANNELS_SUCCESS";
export const SYNC_CHANNELS_FAILURE = "SYNC_CHANNELS_FAILURE";
export const GET_FRIENDS_START = "GET_FRIENDS_START";
export const GET_FRIENDS_SUCCESS = "GET_FRIENDS_SUCCESS";
export const GET_FRIENDS_FAILURE = "GET_FRIENDS_FAILURE";
export const GET_CHANNEL_START = "GET_CHANNEL_START";
export const GET_CHANNEL_SUCCESS = "GET_CHANNEL_SUCCESS";
export const GET_CHANNEL_FAILURE = "GET_CHANNEL_FAILURE";
export const STOP_SYNC_CHANNEL = "STOP_SYNC_CHANNEL";
export const SEND_MESSAGE_START = "SEND_MESSAGE_START";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE";

// EMAIL APP
export const GET_EMAILS = "GET_EMAILS";
export const GET_ALL_EMAILS = "GET_ALL_EMAILS";
export const GET_EMAIL_TYPES = "GET_EMAIL_TYPES";
export const UPDATE_EMAIL_TYPES = "UPDATE_EMAIL_TYPES";

// AUTHENTICATION
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const SYNC_USER_SUCCESS = "SYNC_USER_SUCCESS";
export const SYNC_USER_FAILURE = "SYNC_USER_FAILURE";
export const REQUEST_EMAIL_VERIFICATION_START = "REQUEST_EMAIL_VERIFICATION_START";
export const REQUEST_EMAIL_VERIFICATION_SUCCESS = "REQUEST_EMAIL_VERIFICATION_SUCCESS";
export const REQUEST_EMAIL_VERIFICATION_FAILURE = "REQUEST_EMAIL_VERIFICATION_FAILURE";

//CUSTOMIZER 
export const ADD_COSTOMIZER ="ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS ="ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";

// TOAST
export const SHOW_TOAST = "SHOW_TOAST";

// USER
export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const GET_REQUEST_START = "GET_REQUEST_START"
export const GET_REQUEST_SUCCESS = "GET_REQUEST_SUCCES";
export const GET_REQUEST_FAILURE = "GET_REQUEST_FAILURE";

// QUESTIONS
export const GET_QUESTIONS_START = "GET_QUESTIONS_START";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAILURE = "GET_QUESTIONS_FAILURE";

// COUNTRIES
export const GET_COUNTRIES_START = "GET_COUNTRIES_START";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";
export const GET_STATES_START = "GET_STATES_START";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAILURE = "GET_STATES_FAILURE";

// CHURCHES
export const GET_CHURCHES_START = "GET_CHURCHES_START";
export const GET_CHURCHES_SUCCESS = "GET_CHURCHES_SUCCESS";
export const GET_CHURCHES_FAILURE = "GET_CHURCHES_FAILURE";

// LEADERS
export const GET_LEADERS_FROM_CHURCH_START = "GET_LEADERS_FROM_CHURCH_START";
export const GET_LEADERS_FROM_CHURCH_SUCCESS = "GET_LEADERS_FROM_CHURCH_SUCCESS";
export const GET_LEADERS_FROM_CHURCH_FAILURE = "GET_LEADERS_FROM_CHURCH_FAILURE";
export const CHOOSE_LEADER_START = "CHOOSE_LEADER_START";
export const CHOOSE_LEADER_SUCCESS = "CHOOSE_LEADER_SUCCESS";
export const CHOOSE_LEADER_FAILURE = "CHOOSE_LEADER_FAILURE";
export const USER_REQUEST_LEADER_START = 'USER_REQUEST_LEADER_START'
export const USER_REQUEST_LEADER_SUCCESS = 'USER_REQUEST_LEADER_SUCCESS'
export const USER_REQUEST_LEADER_FAILURE = 'USER_REQUEST_LEADER_FAILURE'
export const LEADER_REQUEST_ADMIN_START = 'LEADER_REQUEST_ADMIN_START'
export const LEADER_REQUEST_ADMIN_SUCCESS = 'LEADER_REQUEST_ADMIN_SUCCESS'
export const LEADER_REQUEST_ADMIN_FAILURE = 'LEADER_REQUEST_ADMIN_FAILURE'
export const GET_REQUESTS_START = 'GET_REQUESTS_START'
export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS'
export const GET_REQUESTS_FAILURE = 'GET_REQUESTS_FAILURE'
export const REPLY_USER_REQUEST_START = 'REPLY_USER_REQUEST_START'
export const REPLY_USER_REQUEST_SUCCESS = 'REPLY_USER_REQUEST_SUCCESS'
export const REPLY_USER_REQUEST_FAILURE = 'REPLY_USER_REQUEST_FAILURE'
export const GET_LEADERS_START = "GET_LEADERS_START";
export const GET_LEADERS_SUCCESS = "GET_LEADERS_SUCCESS";
export const GET_LEADERS_FAILURE = "GET_LEADERS_FAILURE";
export const GET_LEADER_START = "GET_LEADER_START";
export const GET_LEADER_SUCCESS = "GET_LEADER_SUCCESS";
export const GET_LEADER_FAILURE = "GET_LEADER_FAILURE";
export const SET_CURRENT_LEADER = "SET_CURRENT_LEADER";
export const SAVE_LEADER_START = "SAVE_LEADER_START";
export const SAVE_LEADER_SUCCESS = "SAVE_LEADER_SUCCESS";
export const SAVE_LEADER_FAILURE = "SAVE_LEADER_FAILURE";

// MESSAGING
export const SET_MESSAGING_TOKEN_START = 'SET_MESSAGING_TOKEN_START'
export const SET_MESSAGING_TOKEN_SUCCESS = 'SET_MESSAGING_TOKEN_SUCCESS'
export const SET_MESSAGING_TOKEN_FAILURE = 'SET_MESSAGING_TOKEN_FAILURE'

// DISCOVER
export const DISCOVER_START = 'DISCOVER_START'
export const DISCOVER_SUCCESS = 'DISCOVER_SUCCESS'
export const DISCOVER_FAILURE = 'DISCOVER_FAILURE'
export const NEXT_USER_START = 'NEXT_USER_START'
export const NEXT_USER_SUCCESS = 'NEXT_USER_SUCCESS'
export const NEXT_USER_FAILURE = 'NEXT_USER_FAILURE'
export const SET_DISCOVER_USER = 'SET_DISCOVER_USER'
export const LIKE_START = 'LIKE_START'
export const LIKE_SUCCESS = 'LIKE_SUCCESS'
export const LIKE_FAILURE = 'LIKE_FAILURE'

// UPLOADS
export const UPLOAD_PROFILE_IMAGE_START = 'UPLOAD_PROFILE_IMAGE_START'
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS'
export const UPLOAD_PROFILE_IMAGE_FAILURE = 'UPLOAD_PROFILE_IMAGE_FAILURE'

// REQUESTS
export const GET_LEADER_REQUESTS_START = 'GET_LEADER_REQUESTS_START'
export const GET_LEADER_REQUESTS_SUCCESS = 'GET_LEADER_REQUESTS_SUCCESS'
export const GET_LEADER_REQUESTS_FAILURE = 'GET_LEADER_REQUESTS_FAILURE'
export const GET_LEADER_REQUEST_START = 'GET_LEADER_REQUEST_START'
export const GET_LEADER_REQUEST_SUCCESS = 'GET_LEADER_REQUEST_SUCCESS'
export const GET_LEADER_REQUEST_FAILURE = 'GET_LEADER_REQUEST_FAILURE'
export const SET_LEADER_REQUEST_START = 'SET_LEADER_REQUEST_START'
export const SET_LEADER_REQUEST_SUCCESS = 'SET_LEADER_REQUEST_SUCCESS'
export const SET_LEADER_REQUEST_FAILURE = 'SET_LEADER_REQUEST_FAILURE'
export const REPLY_LEADER_REQUEST_START = 'REPLY_LEADER_REQUEST_START'
export const REPLY_LEADER_REQUEST_SUCCESS = 'REPLY_LEADER_REQUEST_SUCCESS'
export const REPLY_LEADER_REQUEST_FAILURE = 'REPLY_LEADER_REQUEST_FAILURE'

export const GET_USER_REQUESTS_START = 'GET_USER_REQUESTS_START'
export const GET_USER_REQUESTS_SUCCESS = 'GET_USER_REQUESTS_SUCCESS'
export const GET_USER_REQUESTS_FAILURE = 'GET_USER_REQUESTS_FAILURE'
export const GET_USER_REQUEST_START = 'GET_USER_REQUEST_START'
export const GET_USER_REQUEST_SUCCESS = 'GET_USER_REQUEST_SUCCESS'
export const GET_USER_REQUEST_FAILURE = 'GET_USER_REQUEST_FAILURE'
export const SET_USER_REQUEST_START = 'SET_USER_REQUEST_START'
export const SET_USER_REQUEST_SUCCESS = 'SET_USER_REQUEST_SUCCESS'
export const SET_USER_REQUEST_FAILURE = 'SET_USER_REQUEST_FAILURE'
export const DELETE_USER_REQUEST_START = 'DELETE_USER_REQUEST_START'
export const DELETE_USER_REQUEST_SUCCESS = 'DELETE_USER_REQUEST_SUCCESS'
export const DELETE_USER_REQUEST_FAILURE = 'DELETE_USER_REQUEST_FAILURE'

export const SET_LEADER_AND_USER_REQUESTS = 'SET_LEADER_AND_USER_REQUESTS';
