
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_START,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  SYNC_USER_SUCCESS,
  // SYNC_USER_FAILURE,
  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  REQUEST_EMAIL_VERIFICATION_START,
  REQUEST_EMAIL_VERIFICATION_SUCCESS,
  REQUEST_EMAIL_VERIFICATION_FAILURE,
} from '../actionTypes';
// import { initNotification } from 'services/notifications';

export const login = (payload) => {
  return { type: LOGIN_START, payload };
};

export const loginSuccess = authRef => {
  return {
    type: LOGIN_SUCCESS,
    payload: authRef
  };
  // initNotification(user.id);
};

export const loginFailure = () => {
  return { type: LOGIN_FAILURE };
};

export const signup = (payload) => {
  return { type: SIGNUP_START, payload };
};

export const signupSuccess = user => {
  return { type: SIGNUP_SUCCESS, user };
  // initNotification(user.id);
};

export const signupFailure = (err) => {
  return { type: SIGNUP_FAILURE, payload: err };
};

export const logout = () => {
  return { type: LOGOUT_START }
};

export const logoutSuccess = () => {
  return { type: LOGOUT_SUCCESS };
};

export const logoutFailure = () => {
  return { type: LOGOUT_FAILURE };
};

export const syncUserSuccess = (user) => {
  return { type: SYNC_USER_SUCCESS, payload: user.data() }
}

export const requestEmailVerification = () => {
  return { type: REQUEST_EMAIL_VERIFICATION_START }
};

export const requestEmailVerificationSuccess = () => {
  return { type: REQUEST_EMAIL_VERIFICATION_SUCCESS };
};

export const requestEmailVerificationFailure = (err) => {
  return { type: REQUEST_EMAIL_VERIFICATION_FAILURE, err };
};