import React from 'react'
import { useDispatch } from 'react-redux';
import {requestEmailVerification} from 'redux/actions/auth.action';

function VerifyEmail() {
  const dispatch = useDispatch();

  return (
    <div className="row flex-column justify-content-center align-items-center">
      <div className="col-lg-6 col-md-8 col-12">
        <div className="card mt-4">
          <div className="card-body">
            <div className="text-center">
              <div className="form-group">
                <h6>please verify your email</h6>
              </div>
              <div className="form-group">
                <button
                  className='form-control btn btn-primary'
                  onClick={() => dispatch(requestEmailVerification())}
                >
                  send email verification again
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail;
