import React, { Fragment, useRef } from 'react';
import Slider from 'react-slick';
import Img from 'react-image'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import noImageAvailable from "assets/images/no-Image-available.jpg";
import Loader from 'components/common/loader';
import { useState } from 'react';

const UserShow = ({ user, photos, actions, leader, request }) => {
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const firstSlider = useRef();
  const secondSlider = useRef();
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="card" style={{ padding: "30px" }}>
          <div className="row product-page-main">
            <div className="col-xl-5">
              <Slider
                asNavFor={secondSlider.current}
                ref={firstSlider}
                className="product-slider"
                onInit={() => setMounted(true)}
              >
                {
                  photos.map((photo, i) => (
                    <div className="item" key={i}>
                      <Img
                        src={photo.fullURL}
                        className="img-fluid m-auto"
                        loader={
                          <div
                            className="min-h-385"
                            style={{ position: 'relative' }}
                          >
                            <Loader position="absolute" />
                          </div>}
                      />
                    </div>
                  ))
                }
              </Slider>
              {
                photos.length > 1 ?
                  <Slider
                    asNavFor={firstSlider.current}
                    ref={secondSlider}
                    slidesToShow={photos.length < 4 ? photos.length : 4}
                    slidesPerRow={1}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    infinite={true}
                    className="small-slick"
                  >
                    {
                      photos.map((photo, i) => (
                        <div className="item" key={i}>
                          <Img
                            src={photo.fullURL}
                            className="img-fluid m-auto"
                            loader={
                              <div
                                style={{ position: 'relative', minHeight: '125px' }}
                              >
                                <Loader position="absolute" />
                              </div>}
                          />
                        </div>
                      ))
                    }
                  </Slider> :
                  <Img src={noImageAvailable} className="img-fluid m-auto" />
              }
            </div>
            <div className="col-xl-7 flex-column d-flex align-items-start">
              {
                user.aboutMe ?
                  <div>
                    <div className="product-page-details">
                      <h5>About me</h5>
                    </div>
                    <hr />
                    <p>{user.aboutMe}</p>
                  </div> : null
              }
              {
                user.church?.name ?
                  <div>
                    <hr />
                    <div className="product-page-details">
                      <h5>Church</h5>
                    </div>
                    <hr />
                    <p>{user.church.name}</p>
                  </div> : null
              }
              {
                user.gender ?
                  <div>
                    <hr />
                    <div className="product-page-details">
                      <h5>Gender</h5>
                    </div>
                    <hr />
                    <p>{user.gender}</p>
                  </div> : null
              }
              {
                user.country?.name ?
                  <div>
                    <hr />
                    <div className="product-page-details">
                      <h5>Country</h5>
                    </div>
                    <hr />
                    <p>{user.country?.name}</p>
                  </div> : null
              }
              {
                leader ?
                  <div>
                    <hr />
                    <div className="product-page-details">
                      <h5>Email</h5>
                    </div>
                    <hr />
                    <p>{user.email}</p>
                  </div> : null
              }
              {
                request?.leaderEmail ?
                  <div>
                    <hr />
                    <div className="product-page-details">
                      <h5>Leader email</h5>
                    </div>
                    <hr />
                    <p>{request.leaderEmail}</p>
                  </div> : null
              }
              {
                request?.leaderName ?
                  <div>
                    <hr />
                    <div className="product-page-details">
                      <h5>Leader name</h5>
                    </div>
                    <hr />
                    <p>{request.leaderName}</p>
                  </div> : null
              }
              {
                request?.message ?
                  <div>
                    <hr />
                    <div className="product-page-details">
                      <h5>Request message</h5>
                    </div>
                    <hr />
                    <p>{request.message}</p>
                  </div> : null
              }
              {
                user.questions?.map(({ question, response }, index) => (
                  <div className="w-100" key={index}>
                    <hr />
                    <div className="product-page-details">
                      <h5>{question}</h5>
                    </div>
                    <hr />
                    <p>{response}</p>
                  </div>
                ))
              }
              {actions}
            </div>

          </div>
        </div>

      </div>
    </Fragment>
  );
}

export default UserShow