import { call, takeLatest, all, put } from "redux-saga/effects";
import {
  getLeaderRequestFailure,
  getLeaderRequestsFailure,
  getLeaderRequestsSuccess,
  getLeaderRequestSuccess,
  getUserRequestsFailure,
  getUserRequestsSuccess,
  getUserRequestSuccess,
  getUserRequestFailure,
} from "redux/actions/request.action";
import rsf, { db } from "redux/rsf";
import {
  GET_LEADER_REQUESTS_START,
  GET_LEADER_REQUEST_START,
  REPLY_LEADER_REQUEST_START,
  GET_USER_REQUESTS_START,
  GET_USER_REQUEST_START,
} from '../actionTypes';

function* getLeaderRequests() {
  try {
    const result = yield call(rsf.firestore.getCollection, db.collection('leaderRequests').where('status', '==', 'pending'));
    let requests = result.docs.map(doc => ({ ...doc.data(), id: doc.id }));

    const leaderPromises = [];
    let churchPromises = [];

    requests.forEach(request => {
      leaderPromises.push(call(rsf.firestore.getDocument, request.leader))
      if (request.church) {
        churchPromises.push(call(rsf.firestore.getDocument, request.church))
      }
    })

    const leadersResult = yield all(leaderPromises);
    let churchResult = yield all(churchPromises);
    let churches = churchResult.filter(doc => doc.exists).map(doc => ({ ...doc.data(), id: doc.id }));
    const leaders = leadersResult.filter(doc => doc.exists).map(doc => doc.data());

    churchPromises = [];
    let countryPromises = [];

    requests.forEach(request => {
      request.leader = leaders.find(leader => leader.id === request.leader.id);
      if (request.church) {
        request.church = churches.find(church => church.id === request.church.id);
      } else {
        if (request.leader?.church) {
          churchPromises.push(call(rsf.firestore.getDocument, request.leader.church));
        }
      }
    });
    requests = requests.filter(request => request.leader);

    churchResult = yield all(churchPromises);
    churches = churchResult.filter(doc => doc.exists).map(doc => ({ ...doc.data(), id: doc.id }));

    requests.forEach(request => {
      if (!request.church) {
        request.church = churches.find(church => request.leader.church.id === church.id)
      }
      countryPromises.push(call(rsf.firestore.getDocument, request.leader.country));
    });

    const countryResult = yield all(countryPromises);
    const countries = countryResult.filter(doc => doc.exists).map(doc => ({ ...doc.data(), id: doc.id }));

    requests.forEach(request => {
      request.leader.country = countries.find(country => country.id === request.leader.country.id)
    });

    yield put(getLeaderRequestsSuccess(requests));
  } catch (err) {
    yield put(getLeaderRequestsFailure(err));
  }
}

function* getLeaderRequest({ payload }) {
  try {
    const result = yield call(rsf.firestore.getDocument, 'leaderRequests/' + payload.id);
    let request = { ...result.data(), id: result.id };

    const leaderDoc = yield call(rsf.firestore.getDocument, request.leader)
    const leader = leaderDoc.data();
    request.leader = leader

    let churchDoc;
    if (request.church) {
      churchDoc = yield call(rsf.firestore.getDocument, request.church);
    } else if (leader.church) {
      churchDoc = yield call(rsf.firestore.getDocument, leader.church);
    }

    if (churchDoc) {
      const church = churchDoc.data();
      request.church = church;
    }

    const countryDoc = yield call(rsf.firestore.getDocument, request.leader.country);
    const country = { ...countryDoc.data(), id: countryDoc.id };
    request.country = country;

    yield put(getLeaderRequestSuccess(request));
  } catch (err) {
    yield put(getLeaderRequestFailure(err));
  }
}

function* replyLeaderRequest({ payload }) {
  try {
    yield call(rsf.firestore.updateDocument, 'leaderRequests/' + payload.id, payload);
    yield put(getLeaderRequestSuccess());
  } catch (err) {
    yield put(getLeaderRequestFailure(err));
  }
}

function* getUserRequests({ payload }) {
  try {
    const result = yield call(rsf.firestore.getCollection, db.collection('requests').where('leader', '==', null).where('status', '==', 'pending'));
    let requests = result.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    
    const usersPromises = requests.map(request => call(rsf.firestore.getDocument, request.user));
    const userResult = yield all(usersPromises);
    const users = userResult.filter(doc => doc.exists).map(doc => doc.data());

    requests.forEach(request => {
      request.user = users.find(user => user.id === request.user.id);
    });

    requests = requests.filter(request => request.user)

    const churchesPromises = users.map(user => call(rsf.firestore.getDocument, user.church));
    const countryPromises = users.map(user => call(rsf.firestore.getDocument, user.country));
    const churchResult = yield all(churchesPromises);
    const countryResult = yield all(countryPromises);
    const churches = churchResult.map(church => ({...church.data(), id: church.id}))
    const countries = countryResult.map(country => ({...country.data(), id: country.id}))

    users.forEach(user => {
      user.church = churches.find(church => church.id === user.church.id)
      user.country = countries.find(country => country.id === user.country.id)
    })

    yield put(getUserRequestsSuccess(requests));
  } catch (err) {
    yield put(getUserRequestsFailure(err));
  }
}

function* getUserRequest({ payload }) {
  try {
    const result = yield call(rsf.firestore.getDocument, 'requests/' + payload.id);
    let request = { ...result.data(), id: result.id };

    const userDoc = yield call(rsf.firestore.getDocument, request.user)
    const user = userDoc.data();
    request.user = user

    let churchDoc;
    if (request.church) {
      churchDoc = yield call(rsf.firestore.getDocument, request.church);
    } else if (user.church) {
      churchDoc = yield call(rsf.firestore.getDocument, user.church);
    }

    if (churchDoc) {
      const church = churchDoc.data();
      request.church = church;
      user.church = church;
    }

    const countryDoc = yield call(rsf.firestore.getDocument, request.user.country);
    const country = { ...countryDoc.data(), id: countryDoc.id };
    request.country = country;
    user.country = country

    yield put(getUserRequestSuccess(request));
  } catch (err) {
    yield put(getUserRequestFailure(err));
  }
}

export default function* requestsSaga() {
  yield all([
    takeLatest(GET_LEADER_REQUESTS_START, getLeaderRequests),
    takeLatest(GET_LEADER_REQUEST_START, getLeaderRequest),
    takeLatest(REPLY_LEADER_REQUEST_START, replyLeaderRequest),
    takeLatest(GET_USER_REQUESTS_START, getUserRequests),
    takeLatest(GET_USER_REQUEST_START, getUserRequest),
    // takeLatest(DELETE_USER_REQUEST_START, deleteUserRequest),
  ]);
}
