/* @jsx glam */
// eslint-disable-next-line
import glam from 'glam'
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Trash2 } from 'react-feather';
import Loader from 'components/common/loader';
import Breadcrumb from '../common/breadcrumb';
import ImageUploader from 'react-images-upload';
import { useSelector, useDispatch } from 'react-redux'
import { getQuestions } from 'redux/actions/question.action';
import { updateUser } from 'redux/actions/user.action';
import { uploadProfileImage } from 'redux/actions/upload.action';

const UserEdit = () => {
  const user = useSelector(({ Auth }) => Auth.user);
  const allQuestions = useSelector(({ Question }) => Question.all);
  const [questions, setQuestions] = useState([...user.questions]);
  const [aboutMe, setAboutMe] = useState(user.aboutMe || '');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const loading = useSelector(({ Auth }) => Auth.updatingUser);
  const loadingQuestions = useSelector(({ Question }) => Question.loading);
  const dispatch = useDispatch();

  const onChangeQuestions = useCallback((question, index) => {
    questions[index] = question;
    setQuestions([...questions]);
  }, [questions]);

  const toggleLightbox = useCallback(index => {
    setSelectedIndex(index || 0);
    setLightboxIsOpen(!lightboxIsOpen);
  }, [lightboxIsOpen]);

  const saveUser = useCallback(() => {
    dispatch(updateUser({ questions, aboutMe }), false, true);
  }, [dispatch, questions, aboutMe])

  const onImagesChange = useCallback((files) => {
    files.forEach(file => {
      if (!images.find(image => image === file)) {
        dispatch(uploadProfileImage({ file }));
      }
    });
    setImages(files)
  }, [dispatch, images])

  const onDeleteImage = useCallback(url => {
    const payload = {
      photos: user.photos.filter(photo => photo.fullURL !== url),
    }
    dispatch(updateUser(payload, true));
  }, [dispatch, user.photos])

  useEffect(() => {
    if (allQuestions.length === 0) {
      dispatch(getQuestions());
    }
  }, [dispatch, allQuestions]);

  useEffect(() => {
    setUploadedImages(user.photos.map(photo => ({
      source: photo.fullURL,
      caption: '',
      ...photo
    })));
  }, [user.photos])

  // if (loading || loadingQuestions) {
  //   return <Loader position='absolute' />;
  // }

  return (
    <Fragment>
      <Breadcrumb parent="User" title="Edit Profile" />
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                {(loading || loadingQuestions) && <Loader position='absolute' />}
                <div className="card-header">
                  <h4 className="card-title mb-0">My Profile</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row mb-2">
                      <div className="col-auto">
                        <img className="img-70 rounded-circle" alt="" src={user.profilePicture.fullURL} />
                      </div>
                      <div className="col">
                        <h3 className="mb-1">{user.firstName} {user.lastName}</h3>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label">Email-Address</label>
                      <input className="form-control" placeholder={user.email} readOnly />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Date of Birth</label>
                      <input className="form-control" type="text" defaultValue={user.birthday?.toDate().toLocaleDateString()} readOnly />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Baptism Date</label>
                      <input className="form-control" type="text" defaultValue={user.baptismDate?.toDate().toLocaleDateString()} readOnly />
                    </div>

                    <div className="form-group">
                      <label className="form-label">Gender</label>
                      <input className="form-control" type="text" defaultValue={user.gender} readOnly />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <form className="card">
                {(loading || loadingQuestions) && <Loader position='absolute' />}
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group mb-0">
                        <label className="form-label">About Me</label>
                        <textarea
                          onChange={({ target }) => setAboutMe(target.value)}
                          className="form-control"
                          rows="5"
                          value={aboutMe}
                        />
                      </div>
                    </div>
                    {
                      questions.map(({ question, response }, index) => (
                        <div className="col-md-12" key={index}>
                          <div className="form-group mb-0">
                            <Select
                              className="mt-4 mb-2"
                              styles={{ margin: "25px 0 15px 0" }}
                              value={{ label: question, value: question }}
                              onChange={({ value }) => onChangeQuestions({ question: value, response }, index)}
                              options={allQuestions.map(value => ({ label: value, value }))}
                            />
                            <textarea
                              className="form-control"
                              rows="5"
                              value={response}
                              onChange={({ target }) => onChangeQuestions({ question, response: target.value }, index)}
                            />
                          </div>
                        </div>
                      ))
                    }

                  </div>
                </div>
                <div className="card-footer text-right">
                  <button onClick={saveUser} className="btn btn-primary" type="button">Update Profile</button>
                </div>
              </form>
            </div>

            <div className="col-md-12">
              <div className="card-body">
                {
                  uploadedImages.length && (
                    <Gallery>
                      {uploadedImages.map(({ caption, source }, j) => (
                        <Image
                          key={source}
                        >
                          <img
                            onClick={() => toggleLightbox(j)}
                            title="Open"
                            alt={caption}
                            src={source}
                            css={{
                              cursor: 'pointer',
                              position: 'absolute',
                              maxWidth: '100%',
                            }}
                          />
                          <div
                            onClick={() => onDeleteImage(source)}
                            title="Delete"
                            style={{
                              position: "absolute",
                              right: 0,
                              top: 0,
                              padding: '0.1rem',
                              backgroundColor: '#ffffffa3',
                              cursor: 'pointer',
                              borderRadius: '0 0 0 8px',
                            }}
                          >
                            <Trash2 />
                          </div>

                        </Image>
                      ))}
                    </Gallery>
                  )
                }
                <ModalGateway>
                  {Number.isInteger(selectedIndex) && lightboxIsOpen ? (
                    <Modal
                      onClose={toggleLightbox}
                      allowFullscreen={false}
                      closeOnBackdropClick={false}
                    >
                      <Carousel
                        components={{ Footer: null, Header }}
                        currentIndex={selectedIndex}
                        // formatters={{ getAltText }}
                        views={uploadedImages}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
                <ImageUploader
                  withIcon={false}
                  // withPreview={true}
                  label=""
                  singleImage={false}
                  buttonText="Upload Images"
                  onChange={onImagesChange}
                  imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif", ".svg"]}
                  fileSizeError=" file size is too big"
                // defaultImages={uploadedImages.map(image => image.url)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default UserEdit;


const gutter = 2;

const Gallery = (props) => (
  <div
    css={{
      overflow: 'hidden',
      marginLeft: -gutter,
      marginRight: -gutter,
    }}
    {...props}
  />
);

const Image = (props) => (
  <div
    css={{
      backgroundColor: '#eee',
      boxSizing: 'border-box',
      float: 'left',
      margin: gutter,
      overflow: 'hidden',
      paddingBottom: '16%',
      position: 'relative',
      width: `calc(25% - ${gutter * 2}px)`,

      ':hover': {
        opacity: 0.9,
      },
    }}
    {...props}
  />
);

const Header = ({ currentView, modalProps }) => {
  const photos = useSelector(({ Auth }) => Auth.user.photos);
  const dispatch = useDispatch();
  const { onClose } = modalProps;

  const onDelete = useCallback(() => {
    const payload = {
      photos: photos.filter(photo => photo.fullURL !== currentView.fullURL) || [],
    }
    dispatch(updateUser(payload, true));
    onClose();
  }, [currentView, onClose, dispatch, photos])



  if (!currentView) return null;

  return (
    <div
      css={{
        alignItems: 'center',
        // backgroundColor: 'white',
        boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',
        // color: colors.N80,
        display: 'flex ',
        flex: '0 0 auto',
        height: 54,
        justifyContent: 'space-between',
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 3,

        '@media (max-width: 769px)': {
          paddingLeft: 10,
          paddingRight: 10,
        },
        '@media (min-width: 770px)': {
          paddingLeft: 20,
          paddingRight: 20,
        },
      }}
    >
      <div css={{ alignItems: 'center', display: 'flex ', minWidth: 0 }}>
        {/* <img
          css={{
            borderRadius: 3,
            flexShrink: 0,
            height: 32,
            marginRight: 8,
            width: 32,
          }}
          src={source}
        /> */}
        <div css={{ fontSize: '0.85em', minWidth: 0 }}>
          {/* <div css={{
            // color: colors.N100,
            fontWeight: 500
          }}>{caption}</div> */}
          <div
            css={{
              // color: colors.N60,
              marginTop: '0.25em',
              minWidth: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {/* <span>{createdDate}</span> */}
            {currentView.caption ? <span> &mdash; {currentView.caption}</span> : null}
          </div>
        </div>
      </div>
      <div css={{ alignItems: 'center', display: 'flex ' }}>
        <Button
          onClick={onDelete}
        >
          <span
            css={{
              // backgroundColor: 'white',
              borderRadius: 8,
              display: 'inline-block',
              fontSize: '0.7em',
              fontWeight: 500,
              lineHeight: 1,
              marginRight: -12,
              marginTop: 8,
              padding: '1px 4px',
              position: 'relative',
            }}
          >
            {/* {likes} */}
          </span>
          <Trash2 />
        </Button>
        <Button
          onClick={onClose}
          css={{
            borderLeft: `1px solid white`,
            paddingLeft: 10,
            '@media (min-width: 770px)': { marginRight: -10 },
          }}
        >
          <Close />
        </Button>
      </div>
    </div>
  );
};

const Close = ({ size = 24, ...props }) => (
  <Svg size={size} {...props}>
    <path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z" />
  </Svg>
);

const Svg = ({ size, ...props }) => (
  <svg
    role="presentation"
    viewBox="0 0 24 24"
    css={{
      display: 'inline-block',
      fill: 'currentColor',
      height: size,
      stroke: 'currentColor',
      strokeWidth: 0,
      width: size,
    }}
    {...props}
  />
);

const Button = ({ css, ...props }) => (
  <div
    css={{
      alignItems: 'center',
      color: 'white',
      cursor: 'pointer',
      display: 'flex ',
      fontWeight: 300,
      height: 32,
      justifyContent: 'center',
      marginLeft: 10,
      position: 'relative',
      textAlign: 'center',
      minWidth: 32,

      '&:hover, &:active': {
        // color: colors.N100,
      },

      ...css,
    }}
    role="button"
    {...props}
  />
);