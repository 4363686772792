import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Loader from 'components/common/loader';
import { showToast } from 'redux/actions/toast.action';
import { getCountries, getStates } from 'redux/actions/country.action';
import { getChurches } from 'redux/actions/church.action';
import { getLeadersFromChurch, chooseLeader, userRequestLeader } from 'redux/actions/leader.action';

const ChooseLeaderStep = () => {
  const loading = useSelector(({ Auth }) => Auth.updatingUser);
  const countries = useSelector(({ Country }) => Country.all);
  const states = useSelector(({ Country }) => Country.states);
  const loadingStates = useSelector(({ Country }) => Country.loadingStates);
  const churches = useSelector(({ Church }) => Church.all);
  const leaders = useSelector(({ Leader }) => Leader.all);
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [church, setChurch] = useState({});
  const [leader, setLeader] = useState({});
  const [requestLeaderModal, setRequestLeaderModal] = useState();
  const [leaderName, setLeaderName] = useState('');
  const [leaderEmail, setLeaderEmail] = useState('');
  const [leaderMessage, setLeaderMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(getCountries());
    }
  }, [dispatch, countries])

  useEffect(() => {
    if (states.length === 0 && !loadingStates && countries.length > 0) {
      dispatch(getChurches(country.value));
    }
  }, [dispatch, loadingStates, states, country, countries])

  const submit = () => {
    if (!leader.value) {
      return dispatch(showToast({
        type: 'warn',
        message: 'please choose a leader',
      }));
    }
    const leaderData = leaders.find(item => item.id === leader.value)
    const leaderID = `${leaderData._parent}/${leaderData.id}`
    const payload = {
      signupStep: 'done',
      churchID: church.value,
      stateID: state.value,
      countryID: country.value,
      leaderID,
    }
    dispatch(chooseLeader(payload));
  }

  const onCountryChange = (newCountry) => {
    dispatch(getStates(newCountry.value))
    setCountry(newCountry);
    setState({});
    setChurch({});
    setLeader({});
  }

  const onStateChange = (value) => {
    dispatch(getChurches(country.value, value.value));
    setState(value);
    setChurch({});
    setLeader({});
  }

  const onChurchChange = (value) => {
    dispatch(getLeadersFromChurch(value.value));
    setChurch(value);
    setLeader({});
  }

  const hasStates = () => country.value && !!states.length;

  const canChooseChurch = () => {
    return country.value && (!hasStates() || state.value);
  }

  const canChooseLeader = () => {
    return canChooseChurch() && church.value;
  }

  const toggleLeaderModal = () => {
    setRequestLeaderModal(!requestLeaderModal)
  }

  const canMakeLeaderRequest = () => {
    return canChooseChurch() && leaderName && leaderEmail;
  }

  const handleRequestLeader = () => {
    const payload = {
      message: leaderMessage,
      leaderName,
      leaderEmail,
    }
    // console.log(payload)
    dispatch(userRequestLeader(payload));
  }


  const CountrySelect = ({ label = true, placeholder = false }) => (
    <div className="form-group">
      {
        label && <label className="col-form-label">Choose your country</label>
      }
      <Select
        placeholder={placeholder && 'Choose your country'}
        value={country.value && country}
        onChange={onCountryChange}
        options={countries.map(country => ({ label: country.name, value: country.iso }))}
      />
    </div>
  );

  const StateSelect = ({ label = true, placeholder = false }) => (
    <div className="form-group">
      {
        label &&
        <label className="col-form-label">Choose your state</label>
      }
      <Select
        placeholder={placeholder && 'Choose your state'}
        value={state.value && state}
        onChange={onStateChange}
        options={states.map(state => ({ label: state.name, value: state.stateCode }))}
      />
    </div>
  )

  const ChurchSelect = ({ label = true, placeholder = false }) => (
    <div className="form-group">
      {
        label &&
        <label className="col-form-label">Choose your church</label>
      }

      <Select
        placeholder={placeholder && 'Choose your church'}

        value={church.value && church}
        onChange={onChurchChange}
        options={churches.map(church => ({ label: church.name, value: church.id }))}
      />
    </div>
  )

  const LeaderSelect = ({ label = true, placeholder = false }) => (
    <div className="form-group">
      {
        label &&
        <label className="col-form-label">Choose your leader name</label>
      }
      <Select
        placeholder={placeholder && 'Choose your leader name'}
        value={leader.value && leader}
        onChange={value => setLeader(value)}
        options={leaders.map(leader => ({ label: `${leader.firstName} ${leader.lastName}`, value: leader.id }))}
      />
    </div>
  )

  return (
    <Fragment>
      {loading && <Loader position='absolute' />}
      <div className="row">
        <div className="col-sm-12">
          <h5 className="display-5 text-center">
            Choose your leader
          </h5>
          <CountrySelect />
          {hasStates() && <StateSelect />}
          {canChooseChurch() && <ChurchSelect />}
          {canChooseLeader() && <LeaderSelect />}
          {
            <div className="form-group text-center">
              <Link to='#' onClick={toggleLeaderModal}>
                can't find your leader?
              </Link>
              <Modal isOpen={requestLeaderModal} toggle={toggleLeaderModal}>
                <form>
                  <ModalHeader toggle={toggleLeaderModal}>
                    Find your leader
                  </ModalHeader>
                  <ModalBody>
                    <CountrySelect placeholder label={false} />
                    {hasStates() && <StateSelect placeholder label={false} />}
                    {canChooseChurch() && <ChurchSelect placeholder label={false} />}
                    {
                      canChooseChurch() &&
                      <>
                        <div className="form-group">
                          <input
                            onChange={e => setLeaderName(e.target.value)}
                            type="text"
                            value={leaderName}
                            className="form-control"
                            placeholder="Write your leader full name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            onChange={e => setLeaderEmail(e.target.value)}
                            type="email"
                            value={leaderEmail}
                            className="form-control"
                            placeholder="Write your leader email"
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            onChange={e => setLeaderMessage(e.target.value)}
                            value={leaderMessage}
                            className="form-control"
                            placeholder="Type a message for your new leader!"
                          />
                        </div>
                      </>
                    }
                  </ModalBody>
                  <ModalFooter>
                    <button
                      disabled={!canMakeLeaderRequest()}
                      className="btn btn-primary btn-block"
                      type="button"
                      onClick={handleRequestLeader}
                    >
                      Send
                    </button>
                  </ModalFooter>
                </form>
              </Modal>
            </div>
          }
          <div className="form-row">
            <div className="col-12">
              <button disabled={!leader.value} className="btn btn-primary btn-block" type="button" onClick={submit}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ChooseLeaderStep;
