import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { updateUser } from 'redux/actions/user.action';
import Loader from 'components/common/loader';
import { getQuestions } from 'redux/actions/question.action';
import { showToast } from 'redux/actions/toast.action';

const QuestionsStep = () => {
  const loading = useSelector(({ Auth }) => Auth.updatingUser);
  const allQuestions = useSelector(({ Question }) => Question.all);
  const loadingQuestions = useSelector(({ Question }) => Question.loading);
  const [firstQuestion, setFirstQuestion] = useState({ question: {}, answer: '' });
  const [secondQuestion, setSecondQuestion] = useState({ question: {}, answer: '' });
  const dispatch = useDispatch();

  const submit = () => {
    if (!firstQuestion.answer || !firstQuestion.question.value || !secondQuestion.answer || !secondQuestion.question.value) {
      return dispatch(showToast({
        type: 'warn',
        message: 'please fill all the questions',
      }))
    }

    const payload = {
      signupStep: 'step 4',
      questions: [{ 
        question: firstQuestion.question.value,
        response: firstQuestion.answer,
      },{ 
        question: secondQuestion.question.value,
        response: secondQuestion.answer,
      }],
    }
    dispatch(updateUser(payload))
  }

  useEffect(() => {
    if (allQuestions.length === 0) {
      dispatch(getQuestions())
    }
  }, [dispatch, allQuestions])

  return (
    <Fragment>
      {(loading || loadingQuestions) && <Loader position='absolute' />}
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label className="col-form-label">Answer some questions about your interests</label>
            <Select
              value={firstQuestion.question}
              onChange={(value) => setFirstQuestion({ ...firstQuestion, question: value })}
              options={allQuestions.map(value => ({ label: value, value }))}
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              value={firstQuestion.answer}
              type='text'
              onChange={e => setFirstQuestion({ ...firstQuestion, answer: e.target.value })}
              placeholder='Your answer...'
              style={{ resize: 'none' }}
            />
          </div>
          <br />
          <div className="form-group">
            {/* <label className="col-form-label">Answer questions about your interests</label> */}
            <Select
              value={secondQuestion.question}
              onChange={(value) => setSecondQuestion({ ...secondQuestion, question: value })}
              options={allQuestions.map(value => ({ label: value, value }))}
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              value={secondQuestion.answer}
              type='text'
              onChange={e => setSecondQuestion({ ...secondQuestion, answer: e.target.value })}
              placeholder='Your answer...'
              style={{ resize: 'none' }}
            />
          </div>
          <div className="form-row">
            <div className="col-12">
              <button className="btn btn-primary btn-block" type="button" onClick={submit}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default QuestionsStep;
