import React, { Fragment } from 'react';
import data from '../../data/dummyTableData';
import Datatable from '../common/datatable';

function DataTableComponent(props) {

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body datatable-react">
                <Datatable
                  multiSelectOption={false}
                  myData={data}
                  pageSize={6}
                  pagination={false}
                  class="-striped -highlight"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DataTableComponent;