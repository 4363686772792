import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Loader from 'components/common/loader';
import Breadcrumb from '../common/breadcrumb';
import { saveLeader } from 'redux/actions/leader.action';
import { getChurches } from 'redux/actions/church.action';
import { getCountries, getStates } from 'redux/actions/country.action';
import { showToast } from 'redux/actions/toast.action';
import { useHistory } from 'react-router';


const CreateLeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(({ Leader }) => Leader.loadingCreate);
  const countries = useSelector(({ Country }) => Country.all);
  const loadingCountries = useSelector(({ Country }) => Country.loading);
  const states = useSelector(({ Country }) => Country.states);
  const loadingStates = useSelector(({ Country }) => Country.loadingStates);
  const churches = useSelector(({ Church }) => Church.all);
  const [filteredChurches, setFilteredChurches] = useState([]);

  const [leader, setLeader] = useState({
    firstName: '',
    lastName: '',
    email: '',
    validated: true,
    church: {},
    country: {},
    onlyLeaderData: true,
    role: 'leader',
  })
  const [country, setCountry] = useState({});
  const [currentState, setCurrentState] = useState({});
  const [newChurch, setNewChurch] = useState({});

  const save = () => {
    const payload = {
      ...leader,
    }

    if (newChurch.value) {
      payload.church = newChurch.value;
    }

    if (country.value) {
      payload.country = country.value;
    }

    if (!payload.firstName || !payload.lastName || !payload.email || !country.value || !newChurch.value) {
      return dispatch(showToast({
        type: 'warn',
        message: 'Please fill in all the fields',
      }));
    }

    const onSuccess = () => {
      history.push('/leader-requests')
    }

    dispatch(saveLeader(payload, onSuccess))
  }

  useEffect(() => {
    if (churches.length === 0)
      dispatch(getChurches());
  }, [dispatch, churches]);

  useEffect(() => {
    if (leader) {
      setLeader(leader);
    }
  }, [leader]);

  useEffect(() => {
    if (country?.value?.iso) {
      dispatch(getStates(country.value.iso));
    }
  }, [country, dispatch]);

  useEffect(() => {
    if (country.value?.iso) {
      if (currentState.value) {
        setFilteredChurches(churches.filter(function(ch) {
          if (ch.state === null) return false;
          return (ch.country.id === country.value.iso && ch.state.id === currentState.value.id);
        }));
      } else {
        setFilteredChurches(churches.filter(church => church.country.id === country.value.iso));
      }
    }
  }, [states, churches, country.value, currentState.value]);

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(getCountries());
    }
  }, [dispatch, countries]);


  const hasStates = () => country.value && !!states.length;

  const canChooseChurch = () => {
    return country.value && (!hasStates() || currentState.value);
  }

  if (loading) return <Loader position="absolute" />;


  return (
    <>
      <Breadcrumb title='New' parent="Leaders" />
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col col-lg-6">
              <div className="card">
                {(loading) && <Loader position='absolute' />}
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label className="form-label">First Name</label>
                      <input
                        className="form-control"
                        value={leader.firstName}
                        type="text"
                        name="firstname"
                        onChange={({ target }) => setLeader({ ...leader, firstName: target.value })}
                      />
                    </div>

                    <div className="form-group">
                      <label className="form-label">Last Name</label>
                      <input
                        className="form-control"
                        placeholder={leader.email}
                        type="text"
                        name="lastname"
                        onChange={({ target }) => setLeader({ ...leader, lastName: target.value })}
                      />
                    </div>

                    <div className="form-group">
                      <label className="form-label">Email-Address</label>
                      <input
                        className="form-control"
                        placeholder={leader.email}
                        type="email"
                        name="email"
                        onChange={({ target }) => setLeader({ ...leader, email: target.value })}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col col-lg-6">
              <form className="card">
                {loading && <Loader position='absolute' />}
                <div className="card-body">
                  <div className="form-group">
                    <label className="form-label">Status</label>
                    <Select
                      value={{ label: leader.validated ? 'active' : 'inactive', value: leader.validated }}
                      onChange={({ value }) => setLeader({ ...leader, validated: value })}
                      options={[{ label: 'active', value: true }, { label: 'inactive', value: false }]}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Country</label>
                    <Select
                      placeholder="Choose your country"
                      isLoading={loadingCountries}
                      value={country}
                      onChange={(newCountry) => {
                        setCurrentState({})
                        setNewChurch({})
                        setCountry(newCountry)
                      }}
                      options={countries.map(country => ({ label: country.name, value: country }))}
                    />
                  </div>
                  {
                    hasStates() &&
                    <div className="form-group">
                      <label className="form-label">State or City</label>
                      <Select
                        placeholder="Choose your country"
                        isLoading={loadingStates}
                        value={currentState}
                        onChange={(newState) => {
                          setNewChurch({})
                          setCurrentState(newState)
                        }}
                        options={states.map(state => ({ label: state.name, value: state }))}
                      />
                    </div>
                  }
                  {
                    canChooseChurch() &&
                    < div className="form-group">
                      <label className="form-label">Church</label>
                      <Select
                        value={newChurch}
                        onChange={(church) => setNewChurch(church)}
                        options={(country.value ? filteredChurches : churches).map(church => ({ label: church.name, value: church }))}
                      />
                    </div>
                  }
                </div>
                <div className="card-footer text-right">
                  <button onClick={save} className="btn btn-primary" type="button">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateLeader
