import React from 'react';
import UserList from 'components/common/userList';
import Breadcrumb from 'components/common/breadcrumb';

const LeadersDashboard = () => {
  return (
    <>
      <Breadcrumb title="Requests"/>
      <div className="container-fluid product-wrapper">
        <div className="product-grid">
          <UserList />
        </div>
      </div>
    </>
  )
}

export default LeadersDashboard;
