import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { storage } from 'redux/rsf';
import { updateUser } from 'redux/actions/user.action';
import Loader from 'components/common/loader';
import { Image } from 'react-feather';
import { showToast } from 'redux/actions/toast.action';

const imageState = () => ({
  error: false,
  loading: false,
  progress: 0,
  image: {
    caption: '',
    url: '',
    fullURL: ''
  },
});

const PhotosStep = ({ leader }) => {
  const loading = useSelector(({ Auth }) => Auth.updatingUser);
  const user = useSelector(({ Auth }) => Auth.user);
  const [profilePicture, setProfilePicture] = useState(imageState());
  const [firstPicture, setFirstPicture] = useState(imageState());
  const [secondPicture, setSecondPicture] = useState(imageState());
  const dispatch = useDispatch();


  const canContinue = () => {
    if (leader) {
      return !profilePicture.image.fullURL
    }
    return !profilePicture.image.fullURL || !firstPicture.image.fullURL || !secondPicture.image.fullURL
  }

  const submit = () => {
    const payload = {
      signupStep: leader ? 'step 2' : 'step 5',
      profilePicture: profilePicture.image,
      photos: leader ? [] : [firstPicture.image, secondPicture.image],
    }
    if (canContinue()) {
      return dispatch(showToast({
        type: 'warn',
        message: 'please select all images',
      }));
    }
    dispatch(updateUser(payload));
  }

  const handleUploadStart = (set, state) => {
    set({ ...state, loading: true, progress: 0 });
  }

  const handleProgress = (set, state, progress) => {
    set({ ...state, progress, loading: true });
  }

  const handleUploadError = (set, state, error) => {
    set({ ...state, error, loading: false, progress: 0 });
    console.error(error);
  };

  const handleUploadSuccess = async (set, state, filename) => {
    const fileRef = storage.ref(`users/${user.id}`).child(filename)
    const url = fileRef.fullPath;
    const fullURL = await fileRef.getDownloadURL();
    set({
      ...state,
      loading: false,
      progress: 100,
      image: {
        caption: filename,
        url,
        fullURL,
      }
    });
  };

  return (
    <Fragment>
      {loading && <Loader position='absolute' />}
      <div className="row">
        <div className="col-sm-12 mb-3">
          <h5 className="display-5 text-center">
            Choose your profile picture
          </h5>
          <div
            className="form-group position-relative bg-image"
            style={{
              paddingTop: '56.25%',
              backgroundImage: `url("${profilePicture.image.fullURL}")`,
            }}>
            <br />
            <CustomUploadButton
              filename='profilePicture'
              accept="image/*"
              storageRef={storage.ref(`users/${user.id}`)}
              onUploadStart={() => handleUploadStart(setProfilePicture, profilePicture)}
              onUploadError={(err) => handleUploadError(setProfilePicture, profilePicture, err)}
              onUploadSuccess={(filename) => handleUploadSuccess(setProfilePicture, profilePicture, filename)}
              onProgress={(progress) => handleProgress(setProfilePicture, profilePicture, progress)}
              style={{ cursor: 'pointer' }}
            >
              <div className='image-box d-flex justify-content-center align-items-center'>
                {
                  profilePicture.loading ?
                    <Loader position='absolute' /> :
                    <Image size={40} />
                }
              </div>
            </CustomUploadButton>
          </div>
        </div>
        <div className="col-sm-12">
          {
            !leader &&
            <>
              <h5 className="display-5 text-center">
                Choose two photos that describe you
              </h5>
              <div
                className="form-group position-relative mb-3 bg-image"
                style={{
                  paddingTop: '56.25%',
                  backgroundImage: `url("${firstPicture.image.fullURL}")`,
                }}
              >
                <br />
                <CustomUploadButton
                  randomizeFilename
                  accept="image/*"
                  storageRef={storage.ref(`users/${user.id}`)}
                  onUploadStart={() => handleUploadStart(setFirstPicture, firstPicture)}
                  onUploadError={(err) => handleUploadError(setFirstPicture, firstPicture, err)}
                  onUploadSuccess={(filename) => handleUploadSuccess(setFirstPicture, firstPicture, filename)}
                  onProgress={(progress) => handleProgress(setFirstPicture, firstPicture, progress)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className='image-box d-flex justify-content-center align-items-center'>
                    {
                      firstPicture.loading ?
                        <Loader position='absolute' /> :
                        <Image size={40} />
                    }
                  </div>
                </CustomUploadButton>
              </div>
              <div
                className="form-group position-relative mb-3 bg-image"
                style={{
                  paddingTop: '56.25%',
                  backgroundImage: `url("${secondPicture.image.fullURL}")`,
                }}
              >
                <br />
                <CustomUploadButton
                  randomizeFilename
                  accept="image/*"
                  storageRef={storage.ref(`users/${user.id}`)}
                  onUploadStart={() => handleUploadStart(setSecondPicture, secondPicture)}
                  onUploadError={(err) => handleUploadError(setSecondPicture, secondPicture, err)}
                  onUploadSuccess={(filename) => handleUploadSuccess(setSecondPicture, secondPicture, filename)}
                  onProgress={(progress) => handleProgress(setSecondPicture, secondPicture, progress)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className='image-box d-flex justify-content-center align-items-center'>
                    {
                      secondPicture.loading ?
                        <Loader position='absolute' /> :
                        <Image size={40} />
                    }
                  </div>
                </CustomUploadButton>
              </div>
            </>
          }
          <div className="form-row">
            <div className="col-12">
              <button disabled={canContinue()} className="btn btn-primary btn-block" type="button" onClick={submit}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default PhotosStep;
