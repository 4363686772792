import {
  SYNC_CHANNELS_START,
  SYNC_CHANNELS_SUCCESS,
  SYNC_CHANNELS_FAILURE,
  GET_FRIENDS_START,
  GET_FRIENDS_SUCCESS,
  GET_FRIENDS_FAILURE,
  GET_CHANNEL_START,
  GET_CHANNEL_SUCCESS,
  GET_CHANNEL_FAILURE,
  SEND_MESSAGE_START,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  STOP_SYNC_CHANNEL,
} from "redux/actionTypes";

const initial_state = {
  channels: [],
  friends: [],
  threads: [],
  error: '',
  loading: true,
  loadingChannel: false,
  currentUser: null,
  selectedUser: null,
  updateSelectedUser: null,
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case SYNC_CHANNELS_START:
      return { ...state, loading: true };
    case SYNC_CHANNELS_SUCCESS:
      return { ...state, channels: action.payload };
    case SYNC_CHANNELS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case GET_FRIENDS_START:
      return { ...state };
    case GET_FRIENDS_SUCCESS:
      return { ...state, loading: false, friends: action.payload.friends, channels: action.payload.channels };
    case GET_FRIENDS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case GET_CHANNEL_START:
      return { ...state, loadingChannel: true };
    case GET_CHANNEL_SUCCESS:
      return { ...state, loadingChannel: false, threads: action.payload };
    case GET_CHANNEL_FAILURE:
      return { ...state, loadingChannel: false, error: action.payload };

    case SEND_MESSAGE_START:
      return { ...state };
    case SEND_MESSAGE_SUCCESS:
      return { ...state };
    case SEND_MESSAGE_FAILURE:
      return { ...state, error: action.payload };

    case STOP_SYNC_CHANNEL:
      return { ...state, threads: initial_state.threads };

    default: return { ...state };
  }
}
