import {
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_REQUEST_START,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAILURE,
} from '../actionTypes';

const initial_state = {
  selectedUser: {},
  request: {},
  loading: true,
  err: null,
};

export default (state = initial_state, action) => {
  switch (action.type) {

    case GET_USER_START:
      return { ...state, loading: true }
    case GET_USER_SUCCESS:
      return { ...state, loading: false, selectedUser: action.payload }
    case GET_USER_FAILURE:
      return { ...state, loading: false }

    case GET_REQUEST_START:
      return { ...state, loading: true, err: null, }
    case GET_REQUEST_SUCCESS:
      return { ...state, loading: false, request: action.payload }
    case GET_REQUEST_FAILURE:
      return { ...state, loading: false, err: action.payload }

    default: return { ...state };
  }
}
