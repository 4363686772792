import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/common/loader';
import Breadcrumb from '../common/breadcrumb';
import { getUserRequest, setUserRequest } from 'redux/actions/request.action';
import { Redirect, useHistory } from 'react-router';
import { getLeadersFromChurch, replyUserRequest } from 'redux/actions/leader.action';
import Select from 'react-select';
import { db } from 'redux/rsf';
import { showToast } from 'redux/actions/toast.action';
import { getChurches } from 'redux/actions/church.action';
import { getCountries, getStates } from 'redux/actions/country.action';


const UserRequestShow = ({ match: { params } }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(({ Request }) => Request.loading);
  const request = useSelector(({ Request }) => Request.current);
  const leaders = useSelector(({ Leader }) => Leader.all);
  const loadingLeaders = useSelector(({ Leader }) => Leader.loading);
  const countries = useSelector(({ Country }) => Country.all);
  const loadingCountries = useSelector(({ Country }) => Country.loading);
  const states = useSelector(({ Country }) => Country.states);
  const loadingStates = useSelector(({ Country }) => Country.loadingStates);
  const churches = useSelector(({ Church }) => Church.all);
  const [replied, setReplied] = useState(false);
  const [newLeader, setNewLeader] = useState({});
  const [country, setCountry] = useState({});
  const [currentState, setCurrentState] = useState({});
  const [newChurch, setNewChurch] = useState({});
  const [filteredChurches, setFilteredChurches] = useState([]);


  const requestId = params.requestId;

  const handleSave = useCallback(() => {
    const payload = {
      requestRef: `requests/${requestId}`,
      status: 'pending',
      request: {
        leader: db.doc(`users/${newLeader.value?.id}`),
        retry: true,
      }
    }

    if (!newLeader.value) {
      return dispatch(showToast({
        type: 'warn',
        message: 'Please select a leader',
      }));
    }

    const onSuccess = () => history.push('/user-requests')

    dispatch(replyUserRequest(payload, onSuccess));
  }, [dispatch, requestId, newLeader.value, history]);

  useEffect(() => {
    if (!request) {
      dispatch(getUserRequest({ id: requestId }))
    }
    return () => dispatch(setUserRequest(null));
    // eslint-disable-next-line
  }, [requestId, dispatch]);

  // useEffect(() => {
  //   if (leaders.length === 0)
  //     dispatch(getLeaders());
  //   // eslint-disable-next-line
  // }, [dispatch]);

  useEffect(() => {
    if (churches.length === 0)
      dispatch(getChurches());
  }, [dispatch, churches]);

  useEffect(() => {
    if (country?.value?.iso) {
      dispatch(getStates(country.value.iso));
    }
  }, [country, dispatch]);

  useEffect(() => {
    if (country.value?.iso) {
      if (currentState.value) {
        setFilteredChurches(churches.filter(church => church.state && church.country.id === country.value.iso && church.state.id === currentState.value.id));
      } else {
        setFilteredChurches(churches.filter(church => church.country.id === country.value.iso));
      }
    }
  }, [states, churches, country.value, currentState.value]);

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(getCountries());
    }
  }, [dispatch, countries]);

  const hasStates = () => country.value && !!states.length;

  const canChooseChurch = () => {
    return country.value && (!hasStates() || currentState.value);
  }

  const canChooseLeader = () => {
    return canChooseChurch() && newChurch.value;
  }

  if (loading) return <Loader />;

  if (replied) {
    return <Redirect to="/user-requests" />;
  }

  const user = { ...request.user, ...request };

  return (
    <>
      <Breadcrumb title={`${request.user.firstName} ${request.user.lastName}`} parent="User requests" />
      {request.status !== 'pending' && (
        <h5 className="text-center mt-0" style={{ verticalAlign: 'middle' }}>
          <br />
            This request has already been answered
        </h5>
      )}
      <>
        <div className="container-fluid">
          <div className="card" style={{ padding: "30px" }}>
            <div className="row product-page-main">
              {
                user.church?.name ?
                  <div className="col-6">
                    <hr />
                    <div className="product-page-details">
                      <h5>Church</h5>
                    </div>
                    <hr />
                    <p>{user.church.name}</p>
                  </div> : null
              }
              {
                user.country?.name ?
                  <div className="col-6">
                    <hr />
                    <div className="product-page-details">
                      <h5>Country</h5>
                    </div>
                    <hr />
                    <p>{user.country?.name}</p>
                  </div> : null
              }
              {
                user.email ?
                  <div className="col-6">
                    <hr />
                    <div className="product-page-details">
                      <h5>User Email</h5>
                    </div>
                    <hr />
                    <p>{user.email}</p>
                  </div> : null
              }
              {
                request?.leaderEmail ?
                  <div className="col-6">
                    <hr />
                    <div className="product-page-details">
                      <h5>Leader email</h5>
                    </div>
                    <hr />
                    <p>{request.leaderEmail}</p>
                  </div> : null
              }
              {
                request?.leaderName ?
                  <div className="col-6">
                    <hr />
                    <div className="product-page-details">
                      <h5>Leader name</h5>
                    </div>
                    <hr />
                    <p>{request.leaderName}</p>
                  </div> : null
              }
              {
                request?.message ?
                  <div className="col-6">
                    <hr />
                    <div className="product-page-details">
                      <h5>Request message</h5>
                    </div>
                    <hr />
                    <p>{request.message}</p>
                  </div> : null
              }
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col col-md-6">
                  <div className="form-group">
                    <h4>Associate Leader With Above Member</h4>
                    <label className="form-label">Leader Country</label>
                    <Select
                      placeholder="Choose leader country"
                      isLoading={loadingCountries}
                      value={country}
                      onChange={(newCountry) => {
                        setCurrentState({})
                        setNewChurch({})
                        setCountry(newCountry)
                      }}
                      options={countries.map(country => ({ label: country.name, value: country }))}
                    />
                  </div>
                </div>
                {
                  hasStates() &&
                  <div className="col col-md-6">
                    <div className="form-group">
                      <label className="form-label">Leader's State or City</label>
                      <Select
                        placeholder="Choose leader's state or city"
                        isLoading={loadingStates}
                        value={currentState}
                        onChange={(newState) => {
                          setNewChurch({})
                          setCurrentState(newState)
                        }}
                        options={states.map(state => ({ label: state.name, value: state }))}
                      />
                    </div>
                  </div>
                }
                {
                  canChooseChurch() &&
                  <div className="col col-md-6">
                    < div className="form-group">
                      <label className="form-label">Leader's Church</label>
                      <Select
                        placeholder="Choose leader's church"
                        value={newChurch}
                        onChange={(church) => {
                          dispatch(getLeadersFromChurch(church.value?.id));
                          setNewLeader({});
                          setNewChurch(church)
                        }}
                        options={(country.value ? filteredChurches : churches).map(church => ({ label: church.name, value: church }))}
                      />
                    </div>
                  </div>
                }
                {
                  canChooseLeader() &&
                  <div className="col col-md-6">
                    <div className="form-group">
                      <label className="form-label">Leader's Name</label>
                      <Select
                        value={newLeader}
                        onChange={setNewLeader}
                        isLoading={loadingLeaders}
                        options={leaders.map(leader => ({ label: `${leader.firstName} ${leader.lastName}`, value: leader }))}
                      />
                    </div>
                  </div>
                }
                {
                  newLeader.value &&
                  <>
                    <div className="col col-md-6">
                      <div className="form-group">
                        <label className="form-label">Leader Email</label>
                        <input className="form-control" placeholder={newLeader.value.email} readOnly />
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className="row">

                <div className="col col-md-6">
                  <div className="form-group">
                    {
                      request.status === 'pending' ?
                        <>
                          <button
                            className="btn btn-success m-r-10"
                            type="button"
                            data-original-title="btn btn-info-gradien"
                            title=""
                            onClick={handleSave}
                            disabled={!newLeader.value}
                          >
                            Associate Leader
                      </button>

                        </> : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </>
    </>
  );
}

export default UserRequestShow;
